import React from 'react';
import { useHistory } from 'react-router-dom';

// reactstrap components
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';

const MenuLink = ({ title, englishTitle, path, icon, iconType }) => {
	const history = useHistory();

	return (
		<Col lg="3" md="6">
			<Card className="card-stats" style={{ cursor: 'pointer' }} onClick={() => history.push(path)}>
				<CardBody>
					<Row>
						<Col xs="3">
							<div className={`info-icon text-center ${iconType}`}>
								<i className={`tim-icons ${icon}`} />
							</div>
						</Col>
						<Col xs="9">
							<div className="numbers">
								<p className="card-category">{englishTitle}</p>
								<CardTitle tag="h3">{title}</CardTitle>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Col>
	);
};

const Dashboard = ({ history }) => {
	return (
		<div className="mt-5 content">
			<Row>
				<Col xs="12"></Col>
				<MenuLink
					title={'회원 관리'}
					englishTitle={'Customer Management'}
					path={'/admin/user'}
					icon={'icon-single-02'}
					iconType={'icon-success'}
				/>
				<MenuLink
					title={'표준산업분류'}
					englishTitle={'Standard Industrial Classification'}
					path={'/admin/standard-classification'}
					icon={'icon-bank'}
					iconType={'icon-error'}
				/>
				<MenuLink
					title={'태그 관리'}
					englishTitle={'Tag Management'}
					path={'/admin/tag'}
					icon={'icon-tag'}
					iconType={'icon-danger'}
				/>
				<MenuLink
					title={'문의 관리'}
					englishTitle={'Inquiry Management'}
					path={'/admin/inquiry'}
					icon={'icon-chat-33'}
					iconType={'icon-warning'}
				/>
				<MenuLink
					title={'문의 유형 관리'}
					englishTitle={'Customer Management'}
					path={'/admin/inquiry-type'}
					icon={'icon-bullet-list-67'}
					iconType={'icon-info'}
				/>
				<MenuLink
					title={'방법론 관리'}
					englishTitle={'Customer Management'}
					path={'/admin/method'}
					icon={'icon-puzzle-10'}
					iconType={'icon-success'}
				/>
				<MenuLink
					title={'방법론 분야 관리'}
					englishTitle={'Customer Management'}
					path={'/admin/method-type'}
					icon={'icon-bullet-list-67'}
					iconType={'icon-error'}
				/>
				<MenuLink
					title={'지원사업 관리'}
					englishTitle={'Customer Management'}
					path={'/admin/support-project'}
					icon={'icon-support-17'}
					iconType={'icon-danger'}
				/>
				<MenuLink
					title={'지원사업 신청 관리'}
					englishTitle={'Customer Management'}
					path={'/admin/request-project'}
					icon={'icon-laptop'}
					iconType={'icon-warning'}
				/>
				<MenuLink
					title={'약관 관리'}
					englishTitle={'Customer Management'}
					path={'/admin/term'}
					icon={'icon-paper'}
					iconType={'icon-info'}
				/>
				<MenuLink
					title={'시뮬레이션 관리'}
					englishTitle={'Simulation Management'}
					path={'/admin/simulation'}
					icon={'icon-chart-bar-32'}
					iconType={'icon-success'}
				/>
				<MenuLink
					title={'계산식 요소 관리'}
					englishTitle={'Simulation Factor Management'}
					path={'/admin/factor'}
					icon={'icon-atom'}
					iconType={'icon-error'}
				/>
				<MenuLink
					title={'절감기술 관리'}
					englishTitle={'Saving Tech Management'}
					path={'/admin/saving-tech'}
					icon={'icon-bulb-63'}
					iconType={'icon-danger'}
				/>
				<MenuLink
					title={'시뮬레이션 결과 관리'}
					englishTitle={'Simulation Result Management'}
					path={'/admin/simulation-result'}
					icon={'icon-laptop'}
					iconType={'icon-warning'}
				/>
			</Row>
		</div>
	);
};

export default Dashboard;

//
//
//
//
/* <Col lg="3" md="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col xs="5">
										<div className="info-icon text-center icon-warning">
											<i className="tim-icons icon-chat-33" />
										</div>
									</Col>
									<Col xs="7">
										<div className="numbers">
											<p className="card-category">Number</p>
											<CardTitle tag="h3">150GB</CardTitle>
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="tim-icons icon-refresh-01" /> Update Now
								</div>
							</CardFooter>
						</Card>
					</Col> */
