import { DateTime } from 'luxon';

const methodModel = {
	model: {
		methodTypeId: '',
		title: '',
		code: '',
		region: '',
		type: '',
		registrationType: '',
		kind: '',
		startDate: '',
		contents: '',
		exception: '',
	},
	formColumns: [
		{ key: 'methodTypeId', editable: true, type: 'lazy-select', required: true },
		{ key: 'title', editable: true, type: 'text', required: true },
		{ key: 'code', editable: true, type: 'text', required: true },
		{
			key: 'region',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{ label: '국내', value: 'internal' },
				{ label: '해외', value: 'foreign' },
			],
		},
		{
			key: 'type',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{ label: '신규', value: 'new' },
				{ label: '개정', value: 'revision' },
			],
		},
		{
			key: 'registrationType',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{ label: '직권등록', value: 'authority' },
				{ label: '신청등록', value: 'request' },
			],
		},
		{
			key: 'kind',
			editable: true,
			type: 'select',
			required: true,
			options: [{ label: '일반', value: 'normal' }],
		},
		{ key: 'startDate', editable: true, type: 'date', required: true },
		{ key: 'contents', editable: true, type: 'textarea', required: true },
		{ key: 'exception', editable: true, type: 'textarea', required: true },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '고유번호',
			accessor: 'code',
		},
		{
			Header: '제목',
			accessor: 'title',
		},
		{
			Header: '방법론분야',
			accessor: 'methodType.name',
		},
		{
			Header: '시작일',
			accessor: 'startDate',
			Cell: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT),
		},
		{
			Header: '등록일시',
			accessor: 'createdAt',
			Cell: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
			width: 200,
		},
	],
	searchModel: {
		searchField: 'title',
		searchItem: '',
	},
	searchOptions: [
		{
			label: '제목',
			value: 'title',
		},
		{
			label: '고유번호',
			value: 'code',
		},
	],
};

export default methodModel;
