const simulationModel = {
	model: {
		methodId: '',
		title: '',
		type: '',
		baselineFormModel: '[]',
		projectFormModel: '[]',
		leakageFormModel: '[]',
		baselineFormula: '',
		projectFormula: '',
		leakageFormula: '',
		leakageMessage: '',
		leakageDescription: '',
	},
	formColumns: [
		{ key: 'methodId', editable: true, type: 'number', required: false },
		{ key: 'title', editable: true, type: 'text', required: true },
		{
			key: 'type',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{ label: '방법론 일반', value: 'normal' },
				{ label: '소규모', value: 'small' },
				{ label: '극소규모', value: 'verysmall' },
			],
		},
		{ key: 'baselineFormModel', editable: true, type: 'simulation', required: true },
		{ key: 'baselineFormula', editable: true, type: 'textarea', required: false },
		{ key: 'projectFormModel', editable: true, type: 'simulation', required: true },
		{ key: 'projectFormula', editable: true, type: 'textarea', required: false },
		{ key: 'leakageFormModel', editable: true, type: 'simulation', required: true },
		{ key: 'leakageFormula', editable: true, type: 'textarea', required: false },
		{ key: 'leakageMessage', editable: true, type: 'text', required: false },
		{ key: 'leakageDescription', editable: true, type: 'textarea', required: false },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '방법론 제목',
			accessor: 'title',
			width: 250,
		},
		{
			Header: '누출량 입력 해당 여부 메시지',
			accessor: 'leakageMessage',
			width: 200,
		},
		{
			Header: '누출량 입력 해당 여부 설명',
			accessor: 'leakageDescription',
			width: 200,
		},
	],
};

export default simulationModel;
