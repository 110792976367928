/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Dashboard from 'views/Dashboard.js';
import Factor from 'views/Factor';
import Inquiry from 'views/Inquiry';
import InquiryType from 'views/InquiryType';
import Method from 'views/Method';
import MethodType from 'views/MethodType';
import RequestProject from 'views/RequestProject';
import SavingTech from 'views/SavingTech';
import Simulation from 'views/Simulation';
import SimulationResult from 'views/SimulationResult';
import Standard from 'views/Standard';
import SupportProject from 'views/SupportProject';
import Tag from 'views/Tag';
import Term from 'views/Term';
import User from 'views/User';

const routes = [
	{
		path: '/dashboard',
		name: '대시보드',
		icon: 'tim-icons icon-app',
		component: Dashboard,
	},
	{
		path: '/user',
		name: '회원 관리',
		icon: 'tim-icons icon-single-02',
		component: User,
	},
	{
		path: '/standard-classification',
		name: '표준산업분류 관리',
		icon: 'tim-icons icon-bank',
		component: Standard,
	},
	{
		path: '/tag',
		name: '태그 관리',
		icon: 'tim-icons icon-tag',
		component: Tag,
	},
	{
		path: '/inquiry',
		name: '문의 관리',
		icon: 'tim-icons icon-chat-33',
		component: Inquiry,
	},
	{
		path: '/inquiry-type',
		name: '문의 유형 관리',
		icon: 'tim-icons icon-bullet-list-67',
		component: InquiryType,
	},
	{
		path: '/method',
		name: '방법론 관리',
		icon: 'tim-icons icon-puzzle-10',
		component: Method,
	},
	{
		path: '/method-type',
		name: '방법론 분야 관리',
		icon: 'tim-icons icon-bullet-list-67',
		component: MethodType,
	},
	{
		path: '/support-project',
		name: '지원사업 관리',
		icon: 'tim-icons icon-support-17',
		component: SupportProject,
	},
	{
		path: '/request-project',
		name: '지원사업 신청 관리',
		icon: 'tim-icons icon-laptop',
		component: RequestProject,
	},
	{
		path: '/term',
		name: '약관 관리',
		icon: 'tim-icons icon-paper',
		component: Term,
	},
	{
		path: '/simulation',
		name: '시뮬레이션 관리',
		icon: 'tim-icons icon-chart-bar-32',
		component: Simulation,
	},
	{
		path: '/factor',
		name: '계산식 요소 관리',
		icon: 'tim-icons icon-atom',
		component: Factor,
	},
	{
		path: '/saving-tech',
		name: '절감기술 관리',
		icon: 'tim-icons icon-bulb-63',
		component: SavingTech,
	},
	{
		path: '/simulation-result',
		name: ' 시뮬레이션 결과 관리',
		icon: 'tim-icons icon-laptop',
		component: SimulationResult,
	},
	// {
	// 	collapse: true,
	// 	name: 'Pages',
	// 	icon: 'tim-icons icon-image-02',
	// 	state: 'pagesCollapse',
	// 	views: [
	// 		{
	// 			path: '/register',
	// 			name: 'Register',
	// 			mini: 'R',
	// 			component: Register,
	// 			layout: '/auth',
	// 		},
	// 		{
	// 			path: '/user-profile',
	// 			name: 'User Profile',
	// 			mini: 'UP',
	// 			component: User,
	// 			layout: '/admin',
	// 		},
	// 	],
	// },
];

export default routes;
