/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Form, Row } from 'reactstrap';

const API_ENDPOINT = '/standard-industrial-classification-filters';

const StandardFilter = ({ handleSelect }) => {
	const [code1Options, setCode1Options] = useState([]);
	const [code2Options, setCode2Options] = useState([]);
	const [code3Options, setCode3Options] = useState([]);
	const [code4Options, setCode4Options] = useState([]);

	const formik = useFormik({
		initialValues: { code1: '', code2: '', code3: '', code4: '' },
		onSubmit: handleSelect,
	});

	const getCode1Options = async () => {
		const { data } = await axios.get(API_ENDPOINT);
		setCode1Options([{ code1: 'default', codeName1: '전체 대분류' }, ...data.data.rows]);
	};

	const getCode2Options = async () => {
		const { data } = await axios.get(`${API_ENDPOINT}?code1=${formik.values.code1}`);
		setCode2Options([{ code2: 'default', codeName2: '전체 중분류' }, ...data.data.rows]);
	};

	const getCode3Options = async () => {
		const { data } = await axios.get(
			`${API_ENDPOINT}?code1=${formik.values.code1}&code2=${formik.values.code2}`,
		);

		setCode3Options([{ code3: 'default', codeName3: '전체 소분류' }, ...data.data.rows]);
	};

	const getCode4Options = async () => {
		const { data } = await axios.get(
			`${API_ENDPOINT}?code1=${formik.values.code1}&code2=${formik.values.code2}&code3=${formik.values.code3}`,
		);
		setCode4Options([{ code4: 'default', codeName4: '전체 세분류' }, ...data.data.rows]);
	};

	useEffect(() => {
		getCode1Options();
	}, []);

	useEffect(() => {
		if (formik.values.code1 === 'default') {
			setCode4Options([{ code4: 'default', codeName4: '전체 세분류' }]);
			formik.setFieldValue('code4', 'default');
			setCode3Options([{ code3: 'default', codeName3: '전체 소분류' }]);
			formik.setFieldValue('code3', 'default');
			setCode2Options([{ code2: 'default', codeName2: '전체 중분류' }]);
			formik.setFieldValue('code2', 'default');
		} else if (!isEmpty(formik.values.code1)) {
			getCode2Options();
		}
	}, [formik.values.code1]);

	useEffect(() => {
		if (formik.values.code2 === 'default') {
			setCode4Options([{ code4: 'default', codeName4: '전체 세분류' }]);
			formik.setFieldValue('code4', 'default');
			setCode3Options([{ code3: 'default', codeName3: '전체 소분류' }]);
			formik.setFieldValue('code3', 'default');
		} else if (!isEmpty(formik.values.code2)) {
			getCode3Options();
		}
	}, [formik.values.code2]);

	useEffect(() => {
		if (formik.values.code3 === 'default') {
			setCode4Options([{ code4: 'default', codeName4: '전체 세분류' }]);
			formik.setFieldValue('code4', 'default');
		} else if (!isEmpty(formik.values.code3)) {
			getCode4Options();
		}
	}, [formik.values.code3]);

	const onSelect = (key, value) => {
		formik.setFieldValue(key, value);
	};

	useEffect(() => {
		if (formik.dirty) {
			handleSelect(formik.values);
		}
	}, [formik.values]);

	return (
		<Form className="px-3 pt-3" onSubmit={formik.handleSubmit}>
			<Row className="d-flex justify-content-start align-items-center">
				<Col md="3" xs="6">
					<Select
						name={'code1'}
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => onSelect('code1', item.code1)}
						value={code1Options.find(option => option.code1 === formik.values.code1)}
						options={code1Options}
						getOptionLabel={option => option.codeName1}
						getOptionValue={option => option.code1}
						defaultValue={{ code1: 'default', codeName1: '전체 대분류' }}
					/>
				</Col>
				<Col md="3" xs="6">
					<Select
						name={'code2'}
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => onSelect('code2', item.code2)}
						value={code2Options.find(option => option.code2 === formik.values.code2)}
						options={code2Options}
						getOptionLabel={option => option.codeName2}
						getOptionValue={option => option.code2}
						defaultValue={{ code2: 'default', codeName2: '전체 중분류' }}
						isDisabled={isEmpty(formik.values.code1) || formik.values.code1 === 'default'}
					/>
				</Col>
				<Col md="3" xs="6">
					<Select
						name={'code3'}
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => onSelect('code3', item.code3)}
						value={code3Options.find(option => option.code3 === formik.values.code3)}
						options={code3Options}
						getOptionLabel={option => option.codeName3}
						getOptionValue={option => option.code3}
						defaultValue={{ code3: 'default', codeName3: '전체 소분류' }}
						isDisabled={isEmpty(formik.values.code2) || formik.values.code2 === 'default'}
					/>
				</Col>
				<Col md="3" xs="6">
					<Select
						name={'code4'}
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => onSelect('code4', item.code4)}
						value={code4Options.find(option => option.code4 === formik.values.code4)}
						options={code4Options}
						getOptionLabel={option => option.codeName4}
						getOptionValue={option => option.code4}
						defaultValue={{ code4: 'default', codeName4: '전체 세분류' }}
						isDisabled={isEmpty(formik.values.code3) || formik.values.code3 === 'default'}
					/>
				</Col>
			</Row>
		</Form>
	);
};
export default StandardFilter;
