import { isDarkModeAtom } from 'atoms/globalAtoms';
import axios from 'axios';
import { useAtom } from 'jotai';
import { omit } from 'lodash';
import factorModel from 'models/factor';
import { useState } from 'react';
import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Table,
	UncontrolledTooltip,
} from 'reactstrap';
import styled from 'styled-components';
import FormModal from './FormModal';
const AddInputRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	button {
		margin-left: 10px;
	}
`;

const FactorModal = ({ factors, onClickAdd, onClickDelete, isOpen, toggle }) => {
	const [isDarkMode] = useAtom(isDarkModeAtom);
	const [targetFactor, setTargetFactor] = useState(null);
	const [factorEditOn, setFactorOnEdit] = useState(false);
	const [addingFactorId, setAddingFactorId] = useState('');

	const onClickAddFactor = () => {
		onClickAdd(addingFactorId);
		setAddingFactorId('');
	};

	const onChangeInput = e => {
		e.preventDefault();
		setAddingFactorId(e.target.value);
	};

	const onClickEdit = factor => {
		setTargetFactor(factor);
		setFactorOnEdit(true);
	};

	const handleEditFactor = async formData => {
		const dataOnly = omit(formData, ['id']);
		await axios.put(`/factors/${formData.id}`, dataOnly);

		const rowIndex = factors.findIndex(row => row.id === formData.id);

		factors[rowIndex] = { ...formData };
		setFactorOnEdit(false);
	};

	return (
		<>
			<FormModal
				title="계산식 요소"
				data={targetFactor}
				isOpen={factorEditOn}
				toggle={() => setFactorOnEdit(false)}
				mode={'edit'}
				model={factorModel.model}
				modelName={'factor'}
				formColumns={factorModel.formColumns}
				handleSubmit={handleEditFactor}
			/>
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				modalClassName={`modal-long ${isDarkMode && 'modal-black'}`}
				size="lg"
			>
				<ModalHeader className="justify-content-center">{`계산식 요소 보기 (${factors.length})`}</ModalHeader>
				<ModalBody>
					<AddInputRow>
						<Input
							type="number"
							onChange={onChangeInput}
							value={addingFactorId}
							placeholder="추가하려는 계산식 요소 ID를 입력해주세요"
						/>
						<Button
							style={{
								width: '150px',
							}}
							color="primary"
							onClick={onClickAddFactor}
						>
							<i className="tim-icons icon-simple-add mr-1 pb-1" /> 추가
						</Button>
					</AddInputRow>
					<Table responsive>
						<thead className="text-primary">
							<tr>
								<th className="text-center">#</th>
								<th className="text-center">계산식 요소명</th>
								<th className="text-center">설명</th>
								<th className="text-center">수정</th>
								<th className="text-right">연결 삭제</th>
							</tr>
						</thead>
						<tbody>
							{factors.map((factor, index) => (
								<tr key={index}>
									<td width={80} className="text-center">
										{factor.id}
									</td>
									<td width={200} className="text-center">
										{factor.name}
									</td>
									<td>{factor.description}</td>
									<td width={50} className="text-center">
										<Button
											className="btn-link"
											color="info"
											id="editFactor"
											size="sm"
											onClick={() => onClickEdit(factor)}
										>
											<i className="tim-icons icon-pencil" />
										</Button>
										<UncontrolledTooltip delay={0} target="editFactor">
											수정
										</UncontrolledTooltip>
									</td>
									<td width={80} className="text-right">
										<Button
											className="btn-link"
											color="danger"
											id="deleteFactor"
											size="sm"
											onClick={() => onClickDelete(factor.id)}
										>
											<i className="tim-icons icon-simple-remove" />
										</Button>
										<UncontrolledTooltip delay={0} target="deleteFactor">
											연결 삭제
										</UncontrolledTooltip>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</ModalBody>
			</Modal>
		</>
	);
};

export default FactorModal;
