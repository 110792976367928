import { Row, Col, Input, Button, Form } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useEffect } from 'react';

const CategorySearchWithFilter = ({
	handleFilter,
	searchModel,
	selectModel,
	searchOptions,
	selectOptions,
}) => {
	const formik = useFormik({
		initialValues: { ...searchModel, ...selectModel },
		onSubmit: handleFilter,
	});

	const [selectFilterName] = Object.keys(selectModel);

	const onSelect = value => {
		formik.setFieldValue(selectFilterName, value);
	};

	useEffect(() => {
		if (formik.dirty) {
			handleFilter(formik.values);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values[selectFilterName]]);

	const defaultSelectOption = selectOptions[0];

	const defaultSearchOption = searchOptions.find(
		option => option.value === searchModel.searchField,
	);

	return (
		<Form className="px-3 pt-3" onSubmit={formik.handleSubmit}>
			<Row className="d-flex justify-content-start align-items-center">
				<Col md="3" xs="12">
					<Select
						name={selectFilterName}
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => onSelect(item.value)}
						options={selectOptions}
						defaultValue={defaultSelectOption}
						value={selectOptions.find(option => option.value === formik[selectFilterName])}
					/>
				</Col>
				<Col md="2" xs="4">
					<Select
						name="searchField"
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => formik.setFieldValue('searchField', item.value)}
						options={searchOptions}
						defaultValue={defaultSearchOption}
						value={searchOptions.find(option => option.value === formik.searchField)}
					/>
				</Col>
				<Col md="5" xs="8">
					<Input
						name="searchItem"
						onChange={formik.handleChange}
						value={formik.searchItem}
						placeholder="검색어를 입력해주세요."
					/>
				</Col>
				<Col md="2" xs="12">
					<Button style={{ width: '100%' }} type="submit" color="warning">
						검색
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default CategorySearchWithFilter;
