const savingTechModel = {
	model: {
		businessType: '',
		equipType: '',
		improveType: '',
		improveName: '',
		roiDuration: '',
		savingCost: '',
		investCost: '',
		examineYear: '',
		energyConsumptionSize: '',
		energyReductionType: '',
		fuelEnergyReductAmount: '',
		elecEnergyReductAmount: '',
		ghgReductAmount: '',
	},
	formColumns: [
		{ key: 'businessType', editable: true, type: 'text', required: true },
		{ key: 'equipType', editable: true, type: 'text', required: true },
		{ key: 'improveType', editable: true, type: 'text', required: true },
		{ key: 'improveName', editable: true, type: 'text', required: true },
		{ key: 'roiDuration', editable: true, type: 'number', required: true },
		{ key: 'savingCost', editable: true, type: 'number', required: true },
		{ key: 'investCost', editable: true, type: 'number', required: true },
		{ key: 'examineYear', editable: true, type: 'number', required: true },
		{ key: 'energyConsumptionSize', editable: true, type: 'text', required: true },
		{ key: 'energyReductionType', editable: true, type: 'text', required: true },
		{ key: 'fuelEnergyReductAmount', editable: true, type: 'number', required: true },
		{ key: 'elecEnergyReductAmount', editable: true, type: 'number', required: true },
		{ key: 'ghgReductAmount', editable: true, type: 'number', required: true },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 80,
		},
		{
			Header: '업종',
			accessor: 'businessType',
			width: 150,
		},
		{
			Header: '대상설비',
			accessor: 'equipType',
			width: 100,
		},
		{
			Header: '개선구분',
			accessor: 'improveType',
			width: 150,
		},
		{
			Header: '개선활동명',
			accessor: 'improveName',
			width: 200,
		},
		{
			Header: '투자비회수기간',
			accessor: 'roiDuration',
			width: 50,
		},
		{
			Header: '절감액',
			accessor: 'savingCost',
			width: 50,
		},
		{
			Header: '투자비',
			accessor: 'investCost',
			width: 50,
		},
		{
			Header: '진단연도',
			accessor: 'examineYear',
			width: 50,
		},
		{
			Header: '에너지사용량규모',
			accessor: 'energyConsumptionSize',
			width: 100,
		},
		{
			Header: '에너지절감종류',
			accessor: 'energyReductionType',
			width: 100,
		},
		{
			Header: '에너지절감량_연료(toe/년)',
			accessor: 'fuelEnergyReductAmount',
			width: 50,
		},
		{
			Header: '에너지절감량_전력(toe/년)',
			accessor: 'elecEnergyReductAmount',
			width: 50,
		},
		{
			Header: '온실가스감축량(tCO2/년)',
			accessor: 'ghgReductAmount',
			width: 50,
		},
	],
	searchModel: {
		searchField: 'improveName',
		searchItem: '',
	},
	searchOptions: [
		{
			label: '개선활동명',
			value: 'improveName',
		},
		{
			label: '업종',
			value: 'businessType',
		},
		{
			label: '대상설비',
			value: 'equipType',
		},
		{
			label: '개선구분',
			value: 'improveType',
		},
		{
			label: '에너지사용량규모',
			value: 'energyConsumptionSize',
		},
		{
			label: '에너지절감종류',
			value: 'energyReductionType',
		},
	],
};

export default savingTechModel;
