/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from 'axios-hooks';
import { useState, useEffect, useRef } from 'react';
import SavingTechPresenter from './SavingTechPresenter';
import { DateTime } from 'luxon';
import axios from 'axios';
import { omit, isObject, isEmpty } from 'lodash';
import ConfirmDeleteAlert from 'views/components/ConfirmDeleteAlert';
import NotificationAlert from 'react-notification-alert';

const API_ENDPOINT = '/saving-techs';

const SavingTechContainer = () => {
	const [searchQuery, setSearchQuery] = useState({});
	const [offset, setOffset] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [{ data, loading }, fetchData] = useAxios({
		url: API_ENDPOINT,
		params: { offset, size: pageSize },
	});
	const [selectedData, setSelectedData] = useState(null);
	const [targetEditData, setTargetEditData] = useState(null);
	const [isOpenDetail, setIsOpenDetail] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);
	const [formMode, setFormMode] = useState('create');
	const [refinedData, setRefinedData] = useState([]);
	const [dataCount, setDataCount] = useState(0);
	const [deleteAlert, setDeleteAlert] = useState(null);

	const notificationAlertRef = useRef(null);

	useEffect(() => {
		fetchData({ params: { offset, size: pageSize, ...searchQuery } });
	}, [offset, pageSize, searchQuery]);

	useEffect(() => {
		if (data) {
			setRefinedData(data.data.rows ?? []);
			setDataCount(data.data.count);
		}
	}, [data, fetchData]);

	const onClickCreate = () => {
		setFormMode('create');
		setIsOpenForm(true);
	};

	const onClickEdit = targetData => {
		setFormMode('edit');
		setTargetEditData(targetData);
		setIsOpenForm(true);
	};

	const handleSearch = async params => {
		try {
			if (isEmpty(params.searchItem)) {
				if (!isEmpty(searchQuery)) {
					setOffset(0);
					setSearchQuery({});
					await fetchData({ params: { offset: 0, size: pageSize } });
				}
				return null;
			}

			setOffset(0);
			setSearchQuery(params);
			await fetchData({ params: { offset: 0, size: pageSize, ...params } });
		} catch (e) {
			console.error(e);
			if (e.response?.status === 404) {
				setRefinedData([]);
			}
		}
	};

	const handleSubmit = async formData => {
		if (formData.defaultValue === '') {
			delete formData.defaultValue;
		} else {
			formData.defaultValue = parseFloat(formData.defaultValue);
		}

		try {
			if (formData.id) {
				// edit mode
				const dataOnly = omit(formData, ['id']);
				await axios.put(`${API_ENDPOINT}/${formData.id}`, dataOnly);

				const rowIndex = refinedData.findIndex(row => row.id === formData.id);

				refinedData[rowIndex] = { ...formData };
				setRefinedData([...refinedData]);
				setTargetEditData(null);
			} else {
				// create mode
				const {
					data: { data },
				} = await axios.post(API_ENDPOINT, formData);

				setRefinedData([data, ...refinedData]);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setIsOpenForm(false);
		}
	};

	const onClickDelete = id => {
		setDeleteAlert(
			<ConfirmDeleteAlert
				title={'절감기술 삭제'}
				onConfirm={() => handleDelete(id)}
				onCancel={onCancel}
			/>,
		);
	};

	const onCancel = () => {
		setDeleteAlert(null);
	};

	const handleDelete = async targetId => {
		try {
			await axios.delete(`${API_ENDPOINT}/${targetId}`);
		} catch (e) {
			console.error(e);
		} finally {
			const updatedData = refinedData.filter(row => row.id !== targetId);
			setRefinedData([...updatedData]);

			const options = {
				place: 'tr',
				message: `절감기술 #${targetId}가 삭제되었습니다.`,
				type: 'success',
				icon: 'tim-icons icon-check-2',
				autoDismiss: 2,
			};
			notificationAlertRef.current.notificationAlert(options);
		}

		setDeleteAlert(null);
	};

	const toggleOffModal = () => {
		setSelectedData(null);
		setTargetEditData(null);
		setIsOpenDetail(false);
		setIsOpenForm(false);
	};

	const onClickRow = async id => {
		const {
			data: { data },
		} = await axios.get(`${API_ENDPOINT}/${id}`);

		const dataList = Object.entries(data).map(([key, value]) => {
			if (value && ['createdAt', 'updatedAt', 'deletedAt'].includes(key)) {
				const localizedDate = DateTime.fromISO(value);
				return [key, localizedDate.toLocaleString(DateTime.DATETIME_SHORT)];
			}

			return [key, value];
		});

		setSelectedData(dataList.filter(([_, value]) => !isObject(value)));
		setIsOpenDetail(true);
	};

	return (
		<>
			{deleteAlert}
			<NotificationAlert ref={notificationAlertRef} />
			<SavingTechPresenter
				data={refinedData}
				dataCount={dataCount}
				loading={loading}
				onClickEdit={onClickEdit}
				onClickCreate={onClickCreate}
				isOpenForm={isOpenForm}
				formMode={formMode}
				onClickRow={onClickRow}
				isOpenDetail={isOpenDetail}
				selectedData={selectedData}
				targetEditData={targetEditData}
				toggleOffModal={toggleOffModal}
				handleSearch={handleSearch}
				handleSubmit={handleSubmit}
				onClickDelete={onClickDelete}
				offset={offset}
				setOffset={setOffset}
				pageSize={pageSize}
				setPageSize={setPageSize}
			/>
		</>
	);
};

export default SavingTechContainer;
