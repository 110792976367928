import { isDarkModeAtom } from 'atoms/globalAtoms';
import { useAtom } from 'jotai';
import { useState } from 'react';
import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Table,
	UncontrolledTooltip,
} from 'reactstrap';
import styled from 'styled-components';

const AddTagRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	button {
		margin-left: 10px;
	}
`;

const TagModal = ({ tags, onClickAdd, onClickDelete, isOpen, toggle }) => {
	const [isDarkMode] = useAtom(isDarkModeAtom);

	const [addingTagId, setAddingTagId] = useState('');

	const onClickAddTag = () => {
		onClickAdd(addingTagId);
		setAddingTagId('');
	};

	const onChangeInput = e => {
		e.preventDefault();
		setAddingTagId(e.target.value);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			modalClassName={`modal-long ${isDarkMode && 'modal-black'}`}
			size="lg"
		>
			<ModalHeader className="justify-content-center">{`태그 보기 (${tags.length})`}</ModalHeader>
			<ModalBody>
				<AddTagRow>
					<Input
						type="number"
						onChange={onChangeInput}
						value={addingTagId}
						placeholder="추가하려는 태그 ID를 입력해주세요"
					/>
					<Button style={{ width: '150px' }} color="primary" onClick={onClickAddTag}>
						<i className="tim-icons icon-simple-add mr-1 pb-1" /> 추가
					</Button>
				</AddTagRow>
				<Table responsive>
					<thead className="text-primary">
						<tr>
							<th className="text-center">#</th>
							<th>태그명</th>
							<th className="text-right">연결 삭제</th>
						</tr>
					</thead>
					<tbody>
						{tags.map((tag, index) => (
							<tr key={index}>
								<td className="text-center">{tag.id}</td>
								<td>{tag.name}</td>
								<td className="text-right">
									<Button
										className="btn-link"
										color="danger"
										id="tooltip974171201"
										size="sm"
										onClick={() => onClickDelete(tag.id)}
									>
										<i className="tim-icons icon-simple-remove" />
									</Button>
									<UncontrolledTooltip delay={0} target="tooltip974171201">
										연결 삭제
									</UncontrolledTooltip>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</ModalBody>
		</Modal>
	);
};

export default TagModal;
