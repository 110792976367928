import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Login from './layouts/Login';
import Admin from 'layouts/Admin';

const LoggedInRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			localStorage.getItem('token') ? <Component {...props} /> : <Redirect to="/login" />
		}
	/>
);

const AppRouter = () => {
	return (
		<Switch>
			<Route exact path="/login" component={Login} />
			<LoggedInRoute path="/admin" component={Admin} />
			<Redirect from="*" to="/login" />
		</Switch>
	);
};

export default AppRouter;
