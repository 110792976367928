import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import koKR from './ko-KR.json';

const resources = {
	'ko-KR': {
		translation: koKR,
		standard: koKR.standard,
		user: koKR.user,
		tag: koKR.tag,
		inquiry: koKR.inquiry,
		inquiryType: koKR.inquiryType,
		method: koKR.method,
		methodType: koKR.methodType,
		supportProject: koKR.supportProject,
		requestProject: koKR.requestProject,
		term: koKR.term,
		simulation: koKR.simulation,
		factor: koKR.factor,
		savingTech: koKR.savingTech,
		simulationResult: koKR.simulationResult,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: navigator.language,
		fallbackLng: 'ko-KR',
		debug: process.env.NODE_ENV === 'development',

		interpolation: {
			escapeValue: false, // not needed for react!!
		},

		// react i18next special options (optional)
		// override if needed - omit if ok with defaults
		/*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
	});

export default i18n;
