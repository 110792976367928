/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// react plugin for creating notifications over the dashboard
import NotificationAlert from 'react-notification-alert';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import FixedPlugin from 'components/FixedPlugin/FixedPlugin.js';

import routes from './sub-routes';

import logo from 'assets/img/favicon.png';

let ps;

const Admin = props => {
	const [activeColor, setActiveColor] = useState('blue');
	const [sidebarExpand, setSidebarExpand] = useState(false);
	const [opacity, setOpacity] = useState(0);
	const [sidebarOpened, setSidebarOpened] = useState(false);
	const mainPanelRef = useRef(null);
	const notificationAlertRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		if (mainPanelRef.current) {
			mainPanelRef.current.scrollTop = 0;
		}
	}, [location]);

	useEffect(() => {
		let innerMainPanelRef = mainPanelRef;
		if (navigator.platform.indexOf('Win') > -1) {
			document.documentElement.classList.add('perfect-scrollbar-on');
			document.documentElement.classList.remove('perfect-scrollbar-off');
			ps = new PerfectScrollbar(mainPanelRef.current);
			mainPanelRef.current &&
				mainPanelRef.current.addEventListener('ps-scroll-y', showNavbarButton);
			let tables = document.querySelectorAll('.table-responsive');
			for (let i = 0; i < tables.length; i++) {
				ps = new PerfectScrollbar(tables[i]);
			}
		}
		window.addEventListener('scroll', showNavbarButton);
		return function cleanup() {
			if (navigator.platform.indexOf('Win') > -1) {
				ps.destroy();
				document.documentElement.classList.add('perfect-scrollbar-off');
				document.documentElement.classList.remove('perfect-scrollbar-on');
				innerMainPanelRef.current &&
					innerMainPanelRef.current.removeEventListener('ps-scroll-y', showNavbarButton);
			}
			window.removeEventListener('scroll', showNavbarButton);
		};
	}, []);

	const showNavbarButton = () => {
		if (
			document.documentElement.scrollTop > 50 ||
			document.scrollingElement.scrollTop > 50 ||
			(mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
		) {
			setOpacity(1);
		} else if (
			document.documentElement.scrollTop <= 50 ||
			document.scrollingElement.scrollTop <= 50 ||
			(mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
		) {
			setOpacity(0);
		}
	};

	const getRoutes = routes => {
		return routes.map((route, key) => {
			if (route.collapse) {
				return getRoutes(route.views);
			}
			return <Route exact path={`/admin${route.path}`} component={route.component} key={key} />;
		});
	};

	const getActiveRoute = routes => {
		let activeRoute = 'Carbon Data Lab Admin';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (window.location.pathname.indexOf(routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const handleActiveClick = color => {
		setActiveColor(color);
	};
	const handleExpandClick = () => {
		let notifyMessage = '사이드바 ';
		if (document.body.classList.contains('sidebar-mini')) {
			setSidebarExpand(false);
			notifyMessage += '확장';
		} else {
			setSidebarExpand(true);
			notifyMessage += '축소';
		}
		let options = {};
		options = {
			place: 'tr',
			message: notifyMessage,
			type: 'primary',
			icon: 'tim-icons icon-bell-55',
			autoDismiss: 2,
		};
		notificationAlertRef.current.notificationAlert(options);
		document.body.classList.toggle('sidebar-mini');
	};
	const toggleSidebar = () => {
		setSidebarOpened(!sidebarOpened);
		document.documentElement.classList.toggle('nav-open');
	};
	const closeSidebar = () => {
		setSidebarOpened(false);
		document.documentElement.classList.remove('nav-open');
	};
	return (
		<div className="wrapper">
			<div className="rna-container">
				<NotificationAlert ref={notificationAlertRef} />
			</div>
			<div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
				<button className="minimize-sidebar btn btn-link btn-just-icon" onClick={handleExpandClick}>
					<i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
					<i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
				</button>
			</div>
			<Sidebar
				{...props}
				routes={routes}
				activeColor={activeColor}
				logo={{
					outterLink: 'https://carbonnote.co.kr/',
					text: 'Carbon Note',
					imgSrc: logo,
				}}
				closeSidebar={closeSidebar}
			/>
			<div className="main-panel" ref={mainPanelRef} data={activeColor}>
				<AdminNavbar
					{...props}
					handleExpandClick={handleExpandClick}
					brandText={getActiveRoute(routes)}
					sidebarOpened={sidebarOpened}
					toggleSidebar={toggleSidebar}
				/>
				<Switch>
					{getRoutes(routes)}
					<Redirect from="/admin/*" to="/admin/dashboard" />
				</Switch>
				{
					// we don't want the Footer to be rendered on full screen maps page
					props.location.pathname.indexOf('full-screen-map') !== -1 ? null : <Footer fluid />
				}
			</div>
			<FixedPlugin
				activeColor={activeColor}
				sidebarExpand={sidebarExpand}
				handleActiveClick={handleActiveClick}
				handleExpandClick={handleExpandClick}
			/>
		</div>
	);
};

export default Admin;
