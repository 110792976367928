/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from 'axios-hooks';
import { useState, useEffect, useRef } from 'react';
import SupportProjectPresenter from './SupportProjectPresenter';
import { DateTime } from 'luxon';
import axios from 'axios';
import { omit, isObject, isEmpty } from 'lodash';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';
import ConfirmDeleteAlert from 'views/components/ConfirmDeleteAlert';

const API_ENDPOINT = '/support-projects';
const TAG_RELATION_API_ENDPOINT = '/tag-support-projects';

const SupportProjectContainer = () => {
	const [searchQuery, setSearchQuery] = useState({});
	const [offset, setOffset] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [{ data, loading }, fetchData] = useAxios({
		url: API_ENDPOINT,
		params: { offset, size: pageSize },
	});
	const [selectedData, setSelectedData] = useState(null);
	const [selectedDataId, setSelectedDataId] = useState(null);
	const [selectedDataTags, setSelectedDataTags] = useState(null);
	const [targetEditData, setTargetEditData] = useState(null);
	const [isOpenDetail, setIsOpenDetail] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);
	const [isOpenTags, setIsOpenTags] = useState(false);
	const [formMode, setFormMode] = useState('create');
	const [refinedData, setRefinedData] = useState([]);
	const [dataCount, setDataCount] = useState(0);
	const [deleteAlert, setDeleteAlert] = useState(null);
	const [deleteRelationAlert, setDeleteRelationAlert] = useState(null);
	const [addRelationAlert, setAddRelationAlert] = useState(null);

	const notificationAlertRef = useRef(null);

	useEffect(() => {
		getQueryData();
	}, [offset, pageSize, searchQuery]);

	useEffect(() => {
		if (data) {
			setRefinedData(data.data.rows);
			setDataCount(data.data.count);
		}
	}, [data, fetchData]);

	const getQueryData = async () => {
		try {
			await fetchData({ params: { offset, size: pageSize, ...searchQuery } });
		} catch (e) {
			console.error(e?.response);
			setRefinedData([]);
			setDataCount(0);
		}
	};

	const onClickCreate = () => {
		setFormMode('create');
		setIsOpenForm(true);
	};

	const onClickTags = async id => {
		const {
			data: { data },
		} = await axios.get(`${API_ENDPOINT}/${id}`);

		const tagList = data.tagSupportProjects.map(relation => relation.tag);
		setSelectedDataId(id);
		setSelectedDataTags(tagList);
		setIsOpenTags(true);
	};

	const onClickEdit = targetData => {
		setFormMode('edit');
		setTargetEditData(targetData);
		setIsOpenForm(true);
	};

	const handleFilter = async ({ projectType, searchField, searchItem }) => {
		try {
			if (isEmpty(projectType + searchItem)) {
				setOffset(0);
				setSearchQuery({});
				return;
			}

			const params = {};

			if (!isEmpty(projectType) && projectType !== 'default') {
				params[projectType] = true;
			}

			if (!isEmpty(searchItem)) {
				params.searchField = searchField;
				params.searchItem = searchItem;
			}

			setSearchQuery(params);
		} catch (e) {
			console.error(e);
			if (e.response?.status === 404) {
				setRefinedData([]);
			}
		}
	};

	const handleSubmit = async formData => {
		try {
			if (formData.id) {
				// edit mode
				const dataOnly = omit(formData, ['id']);
				await axios.put(`${API_ENDPOINT}/${formData.id}`, dataOnly);

				const rowIndex = refinedData.findIndex(row => row.id === formData.id);

				refinedData[rowIndex] = { ...formData };
				setRefinedData([...refinedData]);
				setTargetEditData(null);
			} else {
				// create mode
				const {
					data: { data },
				} = await axios.post(API_ENDPOINT, formData);

				setRefinedData([data, ...refinedData]);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setIsOpenForm(false);
		}
	};

	const onClickDelete = id => {
		setDeleteAlert(
			<ConfirmDeleteAlert
				title={'지원사업 삭제'}
				onConfirm={() => handleDelete(id)}
				onCancel={onCancelAlert}
			/>,
		);
	};

	const onCancelAlert = () => {
		setDeleteAlert(null);
		setDeleteRelationAlert(null);
		setAddRelationAlert(null);
	};

	const handleDelete = async targetId => {
		try {
			await axios.delete(`${API_ENDPOINT}/${targetId}`);
		} catch (e) {
			console.error(e);
		} finally {
			const updatedData = refinedData.filter(row => row.id !== targetId);
			setRefinedData([...updatedData]);
		}

		setDeleteAlert(null);
	};

	const onClickRow = async id => {
		const {
			data: { data },
		} = await axios.get(`${API_ENDPOINT}/${id}`);

		const dataList = Object.entries(data).map(([key, value]) => {
			if (value && ['createdAt', 'updatedAt', 'deletedAt'].includes(key)) {
				const localizedDate = DateTime.fromISO(value);
				return [key, localizedDate.toLocaleString(DateTime.DATETIME_SHORT)];
			} else if (
				['isDirect', 'isInvestment', 'isDonation', 'isSubsidize', 'isLoan'].includes(key)
			) {
				return [key, value === 1 ? '✅' : '❌'];
			}

			return [key, value];
		});

		setSelectedData(dataList.filter(([_, value]) => !isObject(value)));
		setIsOpenDetail(true);
	};

	const toggleOffModal = () => {
		setSelectedData(null);
		setSelectedDataId(null);
		setSelectedDataTags(null);
		setTargetEditData(null);
		setIsOpenDetail(false);
		setIsOpenForm(false);
		setIsOpenTags(false);
	};

	const onClickDeleteRelation = tagId => {
		setDeleteRelationAlert(
			<ReactBSAlert
				warning
				style={{
					display: 'block',
					marginTop: '-100px',
				}}
				title={`태그 #${tagId} 삭제`}
				onConfirm={() => handleDeleteRelation(tagId)}
				onCancel={onCancelAlert}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				confirmBtnText="삭제"
				cancelBtnText="취소"
				showCancel
				btnSize=""
			>
				{'정말로 삭제하시겠습니까?'}
			</ReactBSAlert>,
		);
	};

	const handleDeleteRelation = async tagId => {
		try {
			await axios.delete(`${TAG_RELATION_API_ENDPOINT}`, {
				data: { tagId, methodId: selectedDataId },
			});
		} catch (e) {
			console.error(e);
		} finally {
			setSelectedDataTags(prev => prev.filter(tag => tag.id !== tagId));

			const options = {
				place: 'tr',
				message: '지원사업과 태그 연결이 해제되었습니다. ',
				type: 'success',
				icon: 'tim-icons icon-check-2',
				autoDismiss: 2,
			};
			notificationAlertRef.current.notificationAlert(options);
		}

		setDeleteRelationAlert(null);
	};

	const onClickAddRelation = tagId => {
		setAddRelationAlert(
			<ReactBSAlert
				style={{
					display: 'block',
					marginTop: '-100px',
				}}
				title={`태그 #${tagId} 추가`}
				onConfirm={() => handleAddRelation(tagId)}
				onCancel={onCancelAlert}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				confirmBtnText="추가"
				cancelBtnText="취소"
				showCancel
			>
				{'추가하시겠습니까?'}
			</ReactBSAlert>,
		);
	};

	const handleAddRelation = async tagId => {
		try {
			await axios.post(`${TAG_RELATION_API_ENDPOINT}`, {
				tagId,
				methodId: selectedDataId,
			});
		} catch (e) {
			console.error(e);
		} finally {
			const { data: newTag } = await axios.get(`/tags/${tagId}`);
			setSelectedDataTags(prev => [...prev, newTag.data]);

			const options = {
				place: 'tr',
				message: '지원사업과 태그 연결이 추가되었습니다. ',
				type: 'success',
				icon: 'tim-icons icon-check-2',
				autoDismiss: 2,
			};
			notificationAlertRef.current.notificationAlert(options);
		}

		setAddRelationAlert(null);
	};

	return (
		<>
			{addRelationAlert}
			{deleteRelationAlert}
			{deleteAlert}
			<NotificationAlert ref={notificationAlertRef} />
			<SupportProjectPresenter
				data={refinedData}
				dataCount={dataCount}
				loading={loading}
				onClickTags={onClickTags}
				onClickEdit={onClickEdit}
				onClickCreate={onClickCreate}
				isOpenForm={isOpenForm}
				formMode={formMode}
				toggleOffModal={toggleOffModal}
				onClickRow={onClickRow}
				isOpenDetail={isOpenDetail}
				selectedData={selectedData}
				isOpenTags={isOpenTags}
				selectedDataTags={selectedDataTags}
				targetEditData={targetEditData}
				handleFilter={handleFilter}
				handleSubmit={handleSubmit}
				handleDelete={handleDelete}
				onClickDelete={onClickDelete}
				onClickAddRelation={onClickAddRelation}
				onClickDeleteRelation={onClickDeleteRelation}
				offset={offset}
				setOffset={setOffset}
				pageSize={pageSize}
				setPageSize={setPageSize}
			/>
		</>
	);
};

export default SupportProjectContainer;
