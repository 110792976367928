const methodTypeModel = {
	model: {
		name: '',
		subName: '',
	},
	formColumns: [
		{ key: 'name', editable: true, type: 'text', required: true },
		{ key: 'subName', editable: true, type: 'text', required: true },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '분야명',
			accessor: 'name',
			width: 200,
		},
		{
			Header: '세분야명',
			accessor: 'subName',
			width: 300,
		},
	],
};

export default methodTypeModel;
