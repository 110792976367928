import { useFormik } from 'formik';
import { useEffect } from 'react';
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Table,
} from 'reactstrap';
import { pick } from 'lodash';
import { isDarkModeAtom } from 'atoms/globalAtoms';
import { useAtom } from 'jotai';
import styled from 'styled-components';
import Select from 'react-select';

const SimulationInfoBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid gray;
	border-radius: 8px;
	margin-bottom: 10px;
	overflow: hidden;
`;

const typeOptions = [
	{ value: 'number', label: 'Number' },
	{ value: 'select', label: 'Select' },
	// { value: 'fuel', label: 'Fuel Amount' },
	{ value: 'fuel-type', label: 'Fuel Type' },
	{ value: 'equip-type', label: 'Equip Type' },
];

const SimulationFormEditor = ({ title, data, isOpen, toggle, mode, formColumns, handleSubmit }) => {
	const [isDarkMode] = useAtom(isDarkModeAtom);

	const formik = useFormik({
		initialValues: { root: data },
	});

	useEffect(() => {
		if (mode === 'edit') {
			const editableData = pick(data, [
				'id',
				...formColumns.map(({ key, editable }) => editable && key),
			]);

			formik.setValues({ ...editableData });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const onChangeCheck = (field, checked) => {
		formik.setFieldValue(field, checked);
	};

	const onSubmit = e => {
		e.preventDefault();
		// console.log(formik.values.root);
		handleSubmit(JSON.stringify(formik.values.root));
		toggle();
	};

	const onChangeType = (field, value) => {
		formik.setFieldValue(field, value);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			modalClassName={`modal-long ${isDarkMode && 'modal-black'}`}
			size="lg"
		>
			<ModalHeader className="justify-content-center">{`${title}`}</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<Form className="form-horizontal">
							<Row>
								<Col>
									<SimulationInfoBox>
										<Table>
											<thead className="text-primary">
												<tr>
													<th className="text-center">폼 이름/대상 변수명</th>
													<th>타입</th>
													<th>표시 여부</th>
													<th>읽기 전용</th>
													<th>필수 값</th>
												</tr>
											</thead>
											<tbody>
												{formik.values.root.map((item, index) => (
													<tr key={index}>
														<td style={{ width: '300px' }} className="text-center">
															<FormGroup>
																{['fuel-type', 'equip-type'].includes(item.type) ? (
																	<Input
																		name={`root[${index}].name`}
																		type="text"
																		onChange={formik.handleChange}
																		value={formik.values.root[index].name}
																		required
																	/>
																) : (
																	<Input
																		name={`root[${index}].target`}
																		type="text"
																		onChange={formik.handleChange}
																		value={formik.values.root[index].target}
																		required
																	/>
																)}
															</FormGroup>
														</td>
														<td style={{ width: '150px' }} className="text-center">
															<FormGroup>
																<Select
																	name={`root[${index}].type`}
																	options={typeOptions}
																	value={typeOptions.find(
																		item => item.value === formik.values.root[index].type,
																	)}
																	onChange={item => onChangeType(`root[${index}].type`, item.value)}
																	menuPlacement={
																		index === formik.values.root.length - 1 ? 'top' : 'bottom'
																	}
																/>
															</FormGroup>
														</td>
														<td>
															<FormGroup check>
																<Label check>
																	<Input
																		name={`root[${index}].visible`}
																		type="checkbox"
																		checked={formik.values.root[index].visible}
																		onChange={e =>
																			onChangeCheck(`root[${index}].visible`, e.target.checked)
																		}
																	/>
																	<span className="form-check-sign">
																		<span className="check"></span>
																	</span>
																</Label>
															</FormGroup>
														</td>
														<td>
															<FormGroup check>
																<Label check>
																	<Input
																		name={`root[${index}].readonly`}
																		type="checkbox"
																		checked={formik.values.root[index].readonly}
																		onChange={e =>
																			onChangeCheck(`root[${index}].readonly`, e.target.checked)
																		}
																	/>
																	<span className="form-check-sign">
																		<span className="check"></span>
																	</span>
																</Label>
															</FormGroup>
														</td>
														<td>
															<FormGroup check>
																<Label check>
																	<Input
																		name={`root[${index}].required`}
																		type="checkbox"
																		checked={formik.values.root[index].required}
																		onChange={e =>
																			onChangeCheck(`root[${index}].required`, e.target.checked)
																		}
																	/>
																	<span className="form-check-sign">
																		<span className="check"></span>
																	</span>
																</Label>
															</FormGroup>
														</td>
													</tr>
												))}
											</tbody>
										</Table>
									</SimulationInfoBox>
								</Col>
							</Row>
							<Row className="justify-content-end">
								<Col className="d-flex justify-content-end" md="4" xs="12">
									<Button color="primary" onClick={onSubmit}>
										<i className="tim-icons icon-check-2 mr-1 pb-1" /> 저장하기
									</Button>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default SimulationFormEditor;
