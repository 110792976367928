import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTable from 'components/ReactTable/ReactTable.js';
import DetailModal from 'views/components/DetailModal';
import FormModal from 'views/components/FormModal';
import { EditAndDeleteActions } from 'views/components/Actions';
import termModel from 'models/term';
import { useTranslation } from 'react-i18next';
import CategoryFilter from 'views/components/CategoryFilter';

const TermPresenter = ({
	data,
	dataCount,
	loading,
	onClickEdit,
	onClickCreate,
	isOpenForm,
	formMode,
	onClickRow,
	isOpenDetail,
	selectedData,
	targetEditData,
	toggleOffModal,
	handleSelect,
	handleSubmit,
	onClickDelete,
	offset,
	setOffset,
	pageSize,
	setPageSize,
}) => {
	const { t } = useTranslation('term');

	const tableColumns = [
		...termModel.tableColumns,
		{
			Header: '유형',
			accessor: 'type',
			Cell: ({ value }) => t(value),
		},
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<EditAndDeleteActions
					rowData={row.original}
					onClickEdit={onClickEdit}
					onClickDelete={onClickDelete}
				/>
			),
			width: 120,
		},
	];

	return (
		<>
			<DetailModal
				title="약관"
				translation={'term'}
				data={selectedData}
				isOpen={isOpenDetail}
				toggle={toggleOffModal}
			/>
			<FormModal
				title="약관"
				data={targetEditData}
				isOpen={isOpenForm}
				toggle={toggleOffModal}
				mode={formMode}
				model={termModel.model}
				modelName={'term'}
				formColumns={termModel.formColumns}
				handleSubmit={handleSubmit}
			/>
			<div className="content">
				<Row className="mt-5">
					<Col xs={12} md={12}>
						<Card>
							<CardHeader className="d-flex justify-content-between align-items-center">
								<CardTitle tag="h4">약관 관리</CardTitle>
								<Button onClick={onClickCreate} color="primary">
									<i className="tim-icons icon-simple-add mr-1 pb-1" /> 약관 등록
								</Button>
							</CardHeader>
							<CategoryFilter
								handleSelect={handleSelect}
								selectModel={termModel.selectModel}
								selectOptionSets={termModel.selectOptionSets}
							/>
							<CardBody>
								<ReactTable
									onClickRow={onClickRow}
									data={data}
									dataCount={dataCount}
									loading={loading}
									columns={tableColumns}
									defaultPageSize={10}
									className="-striped -highlight"
									offset={offset}
									setOffset={setOffset}
									pageSize={pageSize}
									setPageSize={setPageSize}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default TermPresenter;
