/*eslint-disable*/
import React, { useEffect, useMemo, useState } from 'react';
import {
	useTable,
	useFilters,
	useAsyncDebounce,
	useSortBy,
	usePagination,
	useResizeColumns,
	useFlexLayout,
} from 'react-table';
import classnames from 'classnames'; // A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter'; // react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';
import { FormGroup, Input, Row, Col } from 'reactstrap'; // Define a default UI for filtering

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
	const count = preFilteredRows.length;
	return (
		<FormGroup>
			<Input
				type="email"
				value={filterValue || ''}
				onChange={e => {
					setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
				}}
				placeholder={`Search ${count} records...`}
			/>
		</FormGroup>
	);
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, {
		keys: [row => row.values[id]],
	});
} // Let the table remove the filter if the string is empty

fuzzyTextFilterFn.autoRemove = val => !val;

const Table = ({
	columns,
	data,
	dataCount,
	loading,
	onClickRow,
	offset,
	setOffset,
	pageSize,
	setPageSize,
}) => {
	const [pageCount, setPageCount] = useState(0);
	const [pageSelectData, setPageSelectData] = useState([]);

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, visibleColumns } =
		useTable(
			{
				columns,
				data,
				manualPagination: true,
				pageCount,
			},
			useResizeColumns,
			useSortBy,
			usePagination,
			useFlexLayout,
		);

	const onClickPreviousPage = () => {
		setOffset(prev => prev - 1);
	};

	const onClickNextPage = () => {
		setOffset(prev => prev + 1);
	};

	const onClickSelectPage = item => {
		setOffset(item.value);
	};

	useEffect(() => {
		if (data) {
			setPageCount(Math.ceil(dataCount / pageSize));
		}
	}, [data]);

	useEffect(() => {
		const selectOptions = Array.apply(null, Array(pageCount)).map((_, key) => {
			return {
				value: key,
				label: `페이지 ${key + 1}`,
			};
		});
		setPageSelectData(selectOptions);
	}, [pageCount]);

	const numberOfRowsData = useMemo(() => {
		const selectOptions = [5, 10, 20, 25, 50, 100].map(value => {
			return {
				value,
				label: `${value} 개`,
			};
		});
		return selectOptions;
	}, [pageSize]);

	return (
		<>
			<div className="ReactTable -striped -highlight">
				<div className="pagination-top">
					<Row style={{ alignItems: 'center' }} className="-pagination">
						<Col md="3" xs="12">
							<div className="-previous">
								<button
									type="button"
									onClick={onClickPreviousPage}
									disabled={offset === 0}
									className="-btn"
								>
									<i className="tim-icons icon-minimal-left mr-1 pb-1" /> 이전
								</button>
							</div>
						</Col>
						<Col md="3" xs="6">
							<Select
								className="react-select info"
								classNamePrefix="react-select"
								name="singleSelect"
								value={{
									value: offset,
									label: `페이지 ${offset + 1}`,
								}}
								onChange={onClickSelectPage}
								options={pageSelectData}
								placeholder="페이지 선택"
							/>
						</Col>
						<Col md="3" xs="6">
							<Select
								className="react-select info"
								classNamePrefix="react-select"
								name="singleSelect"
								value={{
									value: pageSize,
									label: `${pageSize} 개`,
								}}
								onChange={item => {
									setPageSize(item.value);
								}}
								options={numberOfRowsData}
								placeholder="표시 줄 수"
							/>
						</Col>
						<Col md="3" xs="12">
							<div className="-next">
								<button
									type="button"
									onClick={onClickNextPage}
									disabled={offset === pageCount - 1 || data.length === 0}
									className="-btn"
								>
									다음 <i className="tim-icons icon-minimal-right mr-1 pb-1" />
								</button>
							</div>
						</Col>
					</Row>
				</div>
				<div {...getTableProps()} className="rt-table">
					<div className="rt-thead -header">
						{headerGroups.map(headerGroup => (
							<div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
								{headerGroup.headers.map((column, index) => (
									<div
										{...column.getHeaderProps(column.getSortByToggleProps())}
										className={classnames('rt-th rt-resizable-header', {
											'-cursor-pointer': true,
											'-sort-asc': column.isSorted && !column.isSortedDesc,
											'-sort-desc': column.isSorted && column.isSortedDesc,
										})}
									>
										<div className="rt-resizable-header-content">{column.render('Header')}</div>
										{column.canResize && headerGroup.headers.length - 1 !== index && (
											<div
												{...column.getResizerProps()}
												className={`resizer`}
												onClick={e => e.stopPropagation()}
											>
												<div className={`middle-line ${column.isResizing ? 'isResizing' : ''}`} />
											</div>
										)}
										{/* <div>
                          {headerGroup.headers.length - 1 === key
                          	? null
                          	: column.canFilter
                          	? column.render('Filter')
                          	: null}
                          </div> */}
									</div>
								))}
							</div>
						))}
					</div>
					{!loading && (
						<div {...getTableBodyProps()} className="rt-tbody">
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<div
										{...row.getRowProps()}
										className={classnames(
											'rt-tr',
											{
												' -odd': i % 2 === 0,
											},
											{
												' -even': i % 2 === 1,
											},
										)}
										onClick={() => onClickRow(row.original.id)}
									>
										{row.cells.map(cell => {
											return (
												<div {...cell.getCellProps()} className="rt-td">
													{cell.render('Cell')}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					)}
					{!loading && data.length === 0 && (
						<div style={{ width: '100%' }} className={classnames('rt-tr -odd')}>
							<div className="rt-td d-flex justify-content-center">조회 결과가 없습니다.</div>
						</div>
					)}
				</div>
				<div className="pagination-bottom"></div>
			</div>
		</>
	);
}; // Define a custom filter filter function!

function filterGreaterThan(rows, id, filterValue) {
	return rows.filter(row => {
		const rowValue = row.values[id];
		return rowValue >= filterValue;
	});
} // This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number

filterGreaterThan.autoRemove = val => typeof val !== 'number';

export default Table;
