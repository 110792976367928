const inquiryTypeModel = {
	model: {
		contents: '',
		queryKey: '',
		order: '',
	},
	formModel: {
		contents: '',
		queryKey: '',
	},
	formColumns: [
		{ key: 'contents', editable: true, type: 'text', required: true },
		{ key: 'queryKey', editable: true, type: 'text', required: true },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '유형 내용',
			accessor: 'contents',
			width: 200,
		},
		{
			Header: 'Query Key',
			accessor: 'queryKey',
		},
		{
			Header: '문의수',
			accessor: 'countInquiry',
			width: 100,
		},
	],
};

export default inquiryTypeModel;
