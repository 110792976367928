import { configure } from 'axios-hooks';
import LRU from 'lru-cache';
import axios from 'axios';
import { API_URL } from 'config';

export const axiosConfig = () => {
	//axios-hooks configuration
	const axiosInstance = axios.create({
		baseURL: API_URL,
		withCredentials: true,
	});

	const cache = new LRU({ max: 500 });
	const defaultOptions = {
		manual: true,
	};

	//axios configuration
	axios.defaults.baseURL = API_URL;
	axios.defaults.withCredentials = true;

	//adding authorization header if there is token
	// if (Boolean(localStorage.getItem('token'))) {
	// 	axiosInstance.defaults.headers = {
	// 		Authorization: `Bearer ${localStorage.getItem('token')}`,
	// 	};

	// 	axios.defaults.headers = {
	// 		Authorization: `Bearer ${localStorage.getItem('token')}`,
	// 	};
	// }

	configure({ axios: axiosInstance, cache, defaultOptions });
};
