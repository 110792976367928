import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
} from 'reactstrap';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import axios from 'axios';
import { useAtom } from 'jotai';
import { isLoginAtom } from 'atoms/globalAtoms';

const Login = ({ history }) => {
	const [state, setState] = useState({});
	const [uid, setUid] = useState('');
	const [password, setPassword] = useState('');

	const [isLogin, setIsLogin] = useAtom(isLoginAtom);

	if (isLogin) {
		history.push('/admin/dashboard');
	}

	useEffect(() => {
		document.body.classList.toggle('login-page');
		return function cleanup() {
			document.body.classList.toggle('login-page');
		};
	});

	const onChangeUid = e => {
		setUid(e.target.value);
	};

	const onChangePassword = e => {
		setPassword(e.target.value);
	};

	const onLogin = async e => {
		e.preventDefault();

		if (uid !== '' && password !== '') {
			try {
				const { data } = await axios.post('/session', { uid, password });

				const {
					data: { token },
				} = data;

				localStorage.setItem('token', token);

				setIsLogin(true);
				history.push('/admin/dashboard');
			} catch (error) {
				console.error(error);
			}
		}
	};

	return (
		<>
			<AuthNavbar brandText={'Carbon Data Lab'} />
			<div className="wrapper wrapper-full-page">
				<div className={'full-page login-page'}>
					<div className="content">
						<Container>
							<Col className="ml-auto mr-auto" lg="4" md="6">
								<Form className="form" onSubmit={onLogin}>
									<Card className="card-login card-white">
										<CardHeader>
											<img alt="..." src={require('assets/img/card-primary.png').default} />
											<CardTitle tag="h1">Log in</CardTitle>
										</CardHeader>
										<CardBody>
											<InputGroup
												className={classnames({
													'input-group-focus': state.userIdFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-email-85" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="아이디"
													type="text"
													onFocus={e => setState({ ...state, userIdFocus: true })}
													onBlur={e => setState({ ...state, userIdFocus: false })}
													onChange={onChangeUid}
												/>
											</InputGroup>
											<InputGroup
												className={classnames({
													'input-group-focus': state.passFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-lock-circle" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="비밀번호"
													type="password"
													onFocus={e => setState({ ...state, passFocus: true })}
													onBlur={e => setState({ ...state, passFocus: false })}
													onChange={onChangePassword}
												/>
											</InputGroup>
										</CardBody>
										<CardFooter>
											<Button block className="mb-3" color="primary" size="lg" type="submit">
												로그인
											</Button>
											{/* <div className="pull-left">
												<h6>
													<a
														className="link footer-link"
														href="#pablo"
														onClick={e => e.preventDefault()}
													>
														Create Account
													</a>
												</h6>
											</div>
											<div className="pull-right">
												<h6>
													<a
														className="link footer-link"
														href="#pablo"
														onClick={e => e.preventDefault()}
													>
														Need Help?
													</a>
												</h6>
											</div> */}
										</CardFooter>
									</Card>
								</Form>
							</Col>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
