/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// reactstrap components
import { Nav, Collapse } from 'reactstrap';

var ps;

const Sidebar = ({ routes, activeColor, logo, closeSidebar }) => {
	const [state, setState] = useState({});
	const sidebarRef = useRef(null);
	const location = useLocation();
	useEffect(() => {
		setState(getCollapseStates(routes));
	}, []);
	useEffect(() => {
		// if you are using a Windows Machine, the scrollbars will have a Mac look
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(sidebarRef.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
		}
		return function cleanup() {
			// we need to destroy the false scrollbar when we navigate
			// to a page that doesn't have this component rendered
			if (navigator.platform.indexOf('Win') > -1) {
				ps.destroy();
			}
		};
	});
	// this creates the intial state of this component based on the collapse routes
	// that it gets through props.routes
	const getCollapseStates = routes => {
		let initialState = {};
		routes.map((route, key) => {
			if (route.collapse) {
				initialState = {
					[route.state]: getCollapseInitialState(route.views),
					...getCollapseStates(route.views),
					...initialState,
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.js - route /admin/regular-forms
	const getCollapseInitialState = routes => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	};
	// this function creates the links and collapses that appear in the sidebar (left menu)
	const createLinks = routes => {
		return routes.map((route, key) => {
			if (route.redirect) {
				return null;
			}
			if (route.collapse) {
				var st = {};
				st[route['state']] = !state[route.state];
				return (
					<li className={getCollapseInitialState(route.views) ? 'active' : ''} key={key}>
						<a
							href="#pablo"
							data-toggle="collapse"
							aria-expanded={state[route.state]}
							onClick={e => {
								e.preventDefault();
								setState({ ...state, ...st });
							}}
						>
							{route.icon !== undefined ? (
								<>
									<i className={route.icon} />
									<p>
										{route.name}
										<b className="caret" />
									</p>
								</>
							) : (
								<>
									<span className="sidebar-mini-icon">{route.mini}</span>
									<span className="sidebar-normal">
										{route.name}
										<b className="caret" />
									</span>
								</>
							)}
						</a>
						<Collapse isOpen={state[route.state]}>
							<ul className="nav">{createLinks(route.views)}</ul>
						</Collapse>
					</li>
				);
			}
			return (
				<li className={activeRoute(route.path)} key={key}>
					<NavLink to={`/admin${route.path}`} activeClassName="" onClick={closeSidebar}>
						{route.icon !== undefined ? (
							<>
								<i className={route.icon} />
								<p>{route.name}</p>
							</>
						) : (
							<>
								<span className="sidebar-mini-icon">{route.mini}</span>
								<span className="sidebar-normal">{route.name}</span>
							</>
						)}
					</NavLink>
				</li>
			);
		});
	};
	// verifies if routeName is the one active (in browser input)
	const activeRoute = routeName => {
		return location.pathname === routeName ? 'active' : '';
	};

	let logoImg = null;
	let logoText = null;
	if (logo !== undefined) {
		if (logo.outterLink !== undefined) {
			logoImg = (
				<a
					href={logo.outterLink}
					className="simple-text logo-mini"
					target="_blank"
					onClick={closeSidebar}
				>
					<div className="logo-img">
						<img src={logo.imgSrc} alt="react-logo" />
					</div>
				</a>
			);
			logoText = (
				<a
					href={logo.outterLink}
					className="simple-text logo-normal"
					target="_blank"
					onClick={closeSidebar}
				>
					{logo.text}
				</a>
			);
		} else {
			logoImg = (
				<NavLink to={logo.innerLink} className="simple-text logo-mini" onClick={closeSidebar}>
					<div className="logo-img">
						<img src={logo.imgSrc} alt="react-logo" />
					</div>
				</NavLink>
			);
			logoText = (
				<NavLink to={logo.innerLink} className="simple-text logo-normal" onClick={closeSidebar}>
					{logo.text}
				</NavLink>
			);
		}
	}
	return (
		<div className="sidebar" data={activeColor}>
			<div className="sidebar-wrapper" ref={sidebarRef}>
				{logoImg !== null || logoText !== null ? (
					<div className="logo">
						{logoImg}
						{logoText}
					</div>
				) : null}
				<Nav>{createLinks(routes)}</Nav>
			</div>
		</div>
	);
};

Sidebar.propTypes = {
	activeColor: PropTypes.oneOf(['primary', 'blue', 'green', 'orange', 'red']),
	routes: PropTypes.array.isRequired,
	logo: PropTypes.oneOfType([
		PropTypes.shape({
			innerLink: PropTypes.string.isRequired,
			imgSrc: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
		PropTypes.shape({
			outterLink: PropTypes.string.isRequired,
			imgSrc: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	]),
	// this is used on responsive to close the sidebar on route navigation
	closeSidebar: PropTypes.func,
};

export default Sidebar;
