const userModel = {
	model: {
		uid: '',
		password: '',
		phoneNum: '',
		corporationName: '',
		managerName: '',
		managerDepartment: '',
		managerDuty: '',
		stateAuthUser: 'standby',
		stateAuthFile: 'standby',
	},
	formColumns: [
		{ key: 'uid', editable: false, type: 'email', required: true },
		{ key: 'managerName', editable: true, type: 'text', required: true },
		{ key: 'phoneNum', editable: true, type: 'text', required: false },
		{ key: 'corporationName', editable: true, type: 'text', required: false },
		{ key: 'managerDepartment', editable: true, type: 'text', required: false },
		{ key: 'managerDuty', editable: true, type: 'text', required: false },
		{
			key: 'stateAuthUser',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{
					value: 'standby',
					label: '대기',
				},
				{ value: 'approve', label: '승인' },
				{ value: 'reject', label: '승인 거부' },
			],
		},
		{
			key: 'stateAuthFile',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{
					value: 'standby',
					label: '대기',
				},
				{ value: 'approve', label: '승인' },
				{ value: 'reject', label: '승인 거부' },
			],
		},
	],
	tableColumns: [
		{
			Header: 'ID',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '담당자명',
			accessor: 'managerName',
		},
		{
			Header: '이메일',
			accessor: 'uid',
			width: 200,
		},
		{
			Header: '연락처',
			accessor: 'phoneNum',
		},
		{
			Header: '회사명',
			accessor: 'corporationName',
		},
		{
			Header: '부서',
			accessor: 'managerDepartment',
		},
		{
			Header: '직책',
			accessor: 'managerDuty',
		},
		{
			Header: '계정 승인 상태',
			accessor: 'stateAuthUser',
			Cell: ({ value }) =>
				value === 'approve' ? '승인' : value === 'reject' ? '승인 거부' : '승인 대기',
		},
		{
			Header: '명함 인증 상태',
			accessor: 'stateAuthFile',
			Cell: ({ value }) =>
				value === 'approve' ? '승인' : value === 'reject' ? '승인 거부' : '승인 대기',
		},
	],
	searchModel: {
		searchField: 'uid',
		searchItem: '',
	},
	searchOptions: [
		{
			label: '이메일',
			value: 'uid',
		},
		{
			label: '연락처',
			value: 'phoneNum',
		},
		{
			label: '회사명',
			value: 'corporationName',
		},
		{
			label: '담당자명',
			value: 'managerName',
		},
		{
			label: '부서',
			value: 'managerDepartment',
		},
		{
			label: '직책',
			value: 'managerDuty',
		},
	],
};

export default userModel;
