import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTable from 'components/ReactTable/ReactTable.js';
import standardModel from 'models/standard';
import DetailModal from 'views/components/DetailModal';
import FormModal from 'views/components/FormModal';
import TagModal from 'views/components/TagModal';
import { TagsEditDeleteActions } from 'views/components/Actions';
import StandardFilter from './StandardFilter';

const StandardPresenter = ({
	data,
	dataCount,
	loading,
	fetchData,
	onClickTags,
	onClickEdit,
	onClickCreate,
	isOpenForm,
	formMode,
	onClickRow,
	isOpenDetail,
	selectedData,
	isOpenTags,
	selectedDataTags,
	targetEditData,
	toggleOffModal,
	handleSelect,
	handleSubmit,
	onClickDelete,
	onClickAddRelation,
	onClickDeleteRelation,
	offset,
	setOffset,
	pageSize,
	setPageSize,
}) => {
	const tableColumns = [
		...standardModel.tableColumns,
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<TagsEditDeleteActions
					rowData={row.original}
					onClickTags={onClickTags}
					onClickEdit={onClickEdit}
					onClickDelete={onClickDelete}
				/>
			),
		},
	];

	return (
		<>
			{selectedDataTags && (
				<TagModal
					isOpen={isOpenTags}
					toggle={toggleOffModal}
					tags={selectedDataTags}
					onClickAdd={onClickAddRelation}
					onClickDelete={onClickDeleteRelation}
				/>
			)}
			<DetailModal
				title="표준산업코드"
				translation="standard"
				data={selectedData}
				isOpen={isOpenDetail}
				toggle={toggleOffModal}
			/>
			<FormModal
				title="표준산업코드"
				data={targetEditData}
				isOpen={isOpenForm}
				toggle={toggleOffModal}
				mode={formMode}
				model={standardModel.model}
				modelName={'standard'}
				formColumns={standardModel.formColumns}
				handleSubmit={handleSubmit}
			/>
			<div className="content">
				<Row className="mt-5">
					<Col xs={12} md={12}>
						<Card>
							<CardHeader className="d-flex justify-content-between align-items-center">
								<CardTitle tag="h4">표준산업분류 관리</CardTitle>
								<Button onClick={onClickCreate} color="primary">
									<i className="tim-icons icon-simple-add mr-1 pb-1" /> 표준산업코드 등록
								</Button>
							</CardHeader>
							<StandardFilter handleSelect={handleSelect} />
							<CardBody>
								<ReactTable
									onClickRow={onClickRow}
									data={data}
									dataCount={dataCount}
									loading={loading}
									columns={tableColumns}
									defaultPageSize={10}
									className="-striped -highlight"
									offset={offset}
									setOffset={setOffset}
									pageSize={pageSize}
									setPageSize={setPageSize}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default StandardPresenter;
