import ReactBSAlert from 'react-bootstrap-sweetalert';

const ConfirmDeleteAlert = ({ title, onConfirm, onCancel }) => (
	<ReactBSAlert
		warning
		style={{ display: 'block', marginTop: '-100px' }}
		title={title}
		onConfirm={onConfirm}
		onCancel={onCancel}
		confirmBtnBsStyle="success"
		cancelBtnBsStyle="danger"
		confirmBtnText="삭제"
		cancelBtnText="취소"
		showCancel
		btnSize=""
	>
		{'정말로 삭제하시겠습니까?'}
	</ReactBSAlert>
);

export default ConfirmDeleteAlert;
