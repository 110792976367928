import { useFormik } from 'formik';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from 'reactstrap';
import { pick } from 'lodash';
import Select from 'react-select';
import { isDarkModeAtom } from 'atoms/globalAtoms';
import { useAtom } from 'jotai';
import ReactDatetime from 'react-datetime';

const FormModal = ({
	title,
	data,
	isOpen,
	toggle,
	mode,
	model,
	modelName,
	formColumns,
	handleSubmit,
}) => {
	const [isDarkMode] = useAtom(isDarkModeAtom);

	const { t } = useTranslation(modelName);

	const formik = useFormik({
		initialValues: model,
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		if (mode === 'edit') {
			const formData = pick(data, ['id', ...formColumns.map(({ key }) => key)]);

			formik.setValues({ ...formData });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const onChangeSelect = (key, value) => {
		formik.setFieldValue(key, value);
	};

	const onChangeCheck = e => {
		formik.setFieldValue(e.target.name, e.target.checked);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			modalClassName={`modal-long ${isDarkMode && 'modal-black'}`}
			size="lg"
		>
			<ModalHeader className="justify-content-center">{`${title} ${
				mode === 'edit' ? '수정하기' : '생성하기'
			}`}</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<Form className="form-horizontal" onSubmit={formik.handleSubmit}>
							<Row>
								{formColumns.map(({ key, editable, type, required, options, label }, index) => (
									<Fragment key={index}>
										<Label md="3">{t(key)}</Label>
										{type === 'select' && (
											<Col md="9">
												<FormGroup>
													<Select
														className="react-select info"
														classNamePrefix="react-select"
														name={key}
														value={options.find(option => option.value === formik.values[key])}
														onChange={item => onChangeSelect(key, item.value)}
														options={options}
														readOnly={!editable}
													/>
												</FormGroup>
											</Col>
										)}
										{type === 'checkbox' && (
											<Col md="9" style={{ height: '50px' }}>
												<FormGroup check>
													<Label check>
														<Input
															id={key}
															name={key}
															type={type}
															onChange={onChangeCheck}
															checked={formik.values[key] ?? true}
															readOnly={!editable}
														/>
														{label}
														<span className="form-check-sign">
															<span className="check"></span>
														</span>
													</Label>
												</FormGroup>
											</Col>
										)}
										{['text', 'number', 'email'].includes(type) && (
											<Col md="9">
												<FormGroup>
													<Input
														id={key}
														name={key}
														type={type}
														onChange={formik.handleChange}
														value={formik.values[key] ?? ''}
														required={required}
														readOnly={!editable}
													/>
												</FormGroup>
											</Col>
										)}
										{type === 'textarea' && (
											<Col md="9">
												<FormGroup>
													<Input
														id={key}
														name={key}
														type={type}
														onChange={formik.handleChange}
														value={formik.values[key] ?? ''}
														required={required}
														readOnly={!editable}
													/>
												</FormGroup>
											</Col>
										)}
										{type === 'date' && (
											<Col md="9">
												<FormGroup>
													<ReactDatetime
														value={formik.values[key]}
														onChange={formik.handleChange}
														inputProps={{
															className: 'form-control',
														}}
														timeFormat={false}
													/>
												</FormGroup>
											</Col>
										)}
									</Fragment>
								))}
							</Row>
							<Row className="justify-content-end">
								<Col className="d-flex justify-content-end" md="4" xs="12">
									<Button color="primary" type="submit">
										{mode === 'edit' ? (
											<>
												<i className="tim-icons icon-check-2 mr-1 pb-1" /> 수정하기
											</>
										) : (
											<>
												<i className="tim-icons icon-simple-add mr-1 pb-1" /> 생성하기
											</>
										)}
									</Button>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default FormModal;
