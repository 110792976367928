import { DateTime } from 'luxon';

const simulationResultModel = {
	model: {
		userId: '',
		simulationId: '',
		methodId: '',
		methodTitle: '',
		simulationType: 'normal',
		projectDuration: 10,
		tolerance: 5,
		baselineResult: 0,
		projectResult: 0,
		leakageResult: 0,
		emissionReduction: 0,
		emissionPrice: 0,
		OffsetCreditType: 'KCU22',
		emissionPriceDate: DateTime.now().toISODate(),
		totalEmissionReduction: 0,
		totalReductionPrice: 0,
		inputValue: '',
		baselineFactors: '',
		projectFactors: '',
		leakageFactors: '',
		companyName: '',
	},
	formColumns: [
		{ key: 'userId', editable: true, type: 'number', required: true },
		{ key: 'simulationId', editable: true, type: 'number', required: true },
		{ key: 'methodId', editable: true, type: 'number', required: false },
		{ key: 'methodTitle', editable: true, type: 'text', required: true },
		{
			key: 'simulationType',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{ label: '방법론 일반', value: 'normal' },
				{ label: '소규모', value: 'small' },
				{ label: '극소규모', value: 'ex-small' },
				{ label: '방법론 컨설팅', value: 'consult' },
			],
		},
		{ key: 'projectDuration', editable: true, type: 'number', required: true },
		{ key: 'tolerance', editable: true, type: 'number', required: true },
		{ key: 'baselineResult', editable: true, type: 'number', required: true },
		{ key: 'projectResult', editable: true, type: 'number', required: true },
		{ key: 'leakageResult', editable: true, type: 'number', required: false },
		{ key: 'emissionReduction', editable: true, type: 'number', required: true },
		{ key: 'emissionPrice', editable: true, type: 'number', required: true },
		{ key: 'OffsetCreditType', editable: true, type: 'text', required: true },
		{ key: 'emissionPriceDate', editable: true, type: 'date', required: true },
		{ key: 'totalEmissionReduction', editable: true, type: 'number', required: true },
		{ key: 'totalReductionPrice', editable: true, type: 'number', required: true },
		{ key: 'inputValue', editable: true, type: 'json', required: false },
		{ key: 'baselineFactors', editable: true, type: 'json', required: false },
		{ key: 'projectFactors', editable: true, type: 'json', required: false },
		{ key: 'leakageFactors', editable: true, type: 'json', required: false },
		{ key: 'companyName', editable: true, type: 'text', required: true },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 80,
		},
		{
			Header: '방법론 제목',
			accessor: 'methodTitle',
			width: 250,
		},
		{
			Header: '시뮬레이션 타입',
			accessor: 'simulationType',
			width: 120,
		},
		{
			Header: '사업유효기간',
			accessor: 'projectDuration',
			width: 80,
			Cell: ({ value }) => `${value} 년`,
		},
		{
			Header: '계측 공차(허용 오차)',
			accessor: 'tolerance',
			width: 80,
			Cell: ({ value }) => `${value} %`,
		},
		{
			Header: '베이스라인 배출량',
			accessor: 'baselineResult',
			width: 150,
		},
		{
			Header: '사업후 배출량',
			accessor: 'projectResult',
			width: 150,
		},
		{
			Header: '누출량',
			accessor: 'leakageResult',
			width: 150,
		},
		{
			Header: '탄소 배출 감축량',
			accessor: 'emissionReduction',
			width: 150,
		},
		{
			Header: '상쇄배출권 시세',
			accessor: 'emissionPrice',
			width: 120,
		},
		{
			Header: '상쇄배출권 종목',
			accessor: 'OffsetCreditType',
			width: 100,
		},
		{
			Header: '상쇄배출권 가격 기준 날짜',
			accessor: 'emissionPriceDate',
			width: 150,
		},
		{
			Header: '사업 기간 총 감축량',
			accessor: 'totalEmissionReduction',
			width: 150,
		},
		{
			Header: '사업 기간 총 상쇄배출권 수익',
			accessor: 'totalReductionPrice',
			width: 150,
		},
		{
			Header: '등록일시',
			accessor: 'createdAt',
			Cell: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
			width: 200,
		},
	],
	searchModel: {
		searchField: 'methodTitle',
		searchItem: '',
	},
	searchOptions: [
		{
			label: '방법론 제목',
			value: 'methodTitle',
		},
		{
			label: '상쇄배출권',
			value: 'OffsetCreditName',
		},
		{
			label: '시뮬레이션 타입',
			value: 'simulationType',
		},
	],
};

export default simulationResultModel;
