import React, { useEffect } from 'react';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { axiosConfig } from 'axios/axios';
import { useAtom } from 'jotai';
import { isLoginAtom } from 'atoms/globalAtoms';
import './i18n/i18n';

function App() {
	useEffect(() => {
		document.body.classList.toggle('white-content');
	}, []);

	axiosConfig();

	const [, setIsLogin] = useAtom(isLoginAtom);

	useEffect(() => {
		if (Boolean(localStorage.getItem('token'))) {
			setIsLogin(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
}

export default App;
