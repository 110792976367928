import { useFormik } from 'formik';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from 'reactstrap';
import { pick } from 'lodash';
import Select from 'react-select';
import { isDarkModeAtom } from 'atoms/globalAtoms';
import { useAtom } from 'jotai';
import classNames from 'classnames';

const CustomOption = ({ innerProps, data, isFocused, isSelected }) => {
	return (
		<div
			style={{ cursor: 'pointer' }}
			className={classNames('d-flex justify-content-between react-select__option', {
				'react-select__option--is-selected': isSelected,
				'react-select__option--is-focused': isFocused,
			})}
			{...innerProps}
		>
			<span>{data.label}</span>
			<span style={{ color: 'gray', fontSize: '0.5rem' }}>{data.subLabel}</span>
		</div>
	);
};

const MethodFormModal = ({
	title,
	data,
	isOpen,
	toggle,
	mode,
	model,
	modelName,
	formColumns,
	handleSubmit,
	methodTypeOptions,
}) => {
	const [isDarkMode] = useAtom(isDarkModeAtom);

	const { t } = useTranslation(modelName);

	const formik = useFormik({
		initialValues: model,
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		if (mode === 'edit') {
			const editableData = pick(data, [
				'id',
				...formColumns.map(({ key, editable }) => editable && key),
			]);

			formik.setValues({ ...editableData });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const onChangeSelect = (key, value) => {
		formik.setFieldValue(key, value);
	};

	const onChangeCheck = e => {
		formik.setFieldValue(e.target.name, e.target.checked);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			modalClassName={`modal-long ${isDarkMode && 'modal-black'}`}
			size="lg"
		>
			<ModalHeader className="justify-content-center">{`${title} ${
				mode === 'edit' ? '수정하기' : '생성하기'
			}`}</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<Form className="form-horizontal" onSubmit={formik.handleSubmit}>
							<Row>
								{formColumns.map(({ key, editable, type, required, options, label }, index) => (
									<Fragment key={index}>
										{type === 'lazy-select' && (
											<>
												<Label md="3">{t(key)}</Label>
												<Col md="9">
													<FormGroup>
														<Select
															name={'methodTypeId'}
															className="react-select info"
															classNamePrefix="react-select"
															onChange={item => onChangeSelect(key, item.value)}
															options={methodTypeOptions}
															components={{
																Option: props => <CustomOption {...props} />,
															}}
														/>
													</FormGroup>
												</Col>{' '}
											</>
										)}
										{type === 'select' && (
											<>
												<Label md="3">{t(key)}</Label>
												<Col md="9">
													<FormGroup>
														<Select
															className="react-select info"
															classNamePrefix="react-select"
															name={key}
															onChange={item => onChangeSelect(key, item.value)}
															options={options}
														/>
													</FormGroup>
												</Col>{' '}
											</>
										)}
										{type === 'checkbox' && (
											<>
												<Label md="3">{t(key)}</Label>
												<Col md="9" style={{ height: '50px' }}>
													<FormGroup check>
														<Label check>
															<Input
																id={key}
																name={key}
																type={type}
																onChange={onChangeCheck}
																checked={formik.values[key] ?? true}
																readOnly={!editable}
															/>
															{label}
															<span className="form-check-sign">
																<span className="check"></span>
															</span>
														</Label>
													</FormGroup>
												</Col>{' '}
											</>
										)}
										{['text', 'number'].includes(type) && (
											<>
												<Label md="3">{t(key)}</Label>
												<Col md="9">
													<FormGroup>
														<Input
															id={key}
															name={key}
															type={type}
															onChange={formik.handleChange}
															value={formik.values[key] ?? ''}
															required={required}
															readOnly={!editable}
														/>
													</FormGroup>
												</Col>{' '}
											</>
										)}
										{type === 'textarea' && (
											<>
												<Label md="3">{t(key)}</Label>
												<Col md="9">
													<FormGroup>
														<Input
															id={key}
															name={key}
															type={type}
															onChange={formik.handleChange}
															value={formik.values[key] ?? ''}
															required={required}
															readOnly={!editable}
															style={{ resize: 'vertical' }}
														/>
													</FormGroup>
												</Col>{' '}
											</>
										)}
									</Fragment>
								))}
							</Row>
							<Row className="justify-content-end">
								<Col className="d-flex justify-content-end" md="4" xs="12">
									<Button color="primary" type="submit">
										{mode === 'edit' ? (
											<>
												<i className="tim-icons icon-check-2 mr-1 pb-1" /> 수정하기
											</>
										) : (
											<>
												<i className="tim-icons icon-simple-add mr-1 pb-1" /> 생성하기
											</>
										)}
									</Button>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default MethodFormModal;
