const tagModel = {
	model: {
		name: '',
	},
	formColumns: [{ key: 'name', editable: true, type: 'text', required: true }],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '태그명',
			accessor: 'name',
			width: 200,
		},
		{
			Header: '표준산업분류수',
			accessor: 'countStandardIndustrialClassification',
		},
	],
};

export default tagModel;
