import { Row, Col, Input, Button, Form, Badge } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import styled from 'styled-components';

const SearchButtonInfo = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	div {
		flex: 1;
	}

	div:last-child {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
`;

const CategorySearch = ({ handleSearch, searchModel, searchOptions, searchedCount }) => {
	const formik = useFormik({
		initialValues: searchModel,
		onSubmit: handleSearch,
	});

	const defaultOption = searchOptions.find(option => option.value === searchModel.searchField);

	return (
		<Form className="px-3 pt-3" onSubmit={formik.handleSubmit}>
			<Row className="d-flex justify-content-start align-items-center">
				<Col md="2" xs="4">
					<Select
						name="searchField"
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => formik.setFieldValue('searchField', item.value)}
						options={searchOptions}
						defaultValue={defaultOption}
						value={searchOptions.find(option => option.value === formik.searchField)}
					/>
				</Col>
				<Col md="4" xs="8">
					<Input
						name="searchItem"
						onChange={formik.handleChange}
						value={formik.searchItem}
						placeholder="검색어를 입력해주세요."
					/>
				</Col>
				<Col md="3" xs="12">
					<Button style={{ width: '100%' }} type="submit" color="warning">
						<SearchButtonInfo>
							<div></div>
							<div>검색</div>
							<div>
								<Badge color="default">{searchedCount}</Badge>
							</div>
						</SearchButtonInfo>
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default CategorySearch;
