/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { isDarkModeAtom } from 'atoms/globalAtoms';
import { useAtom } from 'jotai';
import { useState } from 'react';

import { Button, CustomInput } from 'reactstrap';

const FixedPlugin = ({ activeColor, handleActiveClick, sidebarExpand, handleExpandClick }) => {
	const [classes, setClasses] = useState('dropdown');
	const [darkMode, setDarkMode] = useAtom(isDarkModeAtom);
	const handleClick = () => {
		if (classes === 'dropdown') {
			setClasses('dropdown show');
		} else {
			setClasses('dropdown');
		}
	};
	const handleActiveMode = () => {
		setDarkMode(!darkMode);
		document.body.classList.toggle('white-content');
	};
	return (
		<div className="fixed-plugin">
			<div className={classes}>
				<a
					href="#pablo"
					onClick={e => {
						e.preventDefault();
						handleClick();
					}}
				>
					<i className="fa fa-cog fa-2x" />
				</a>
				<ul className="dropdown-menu show">
					<li className="header-title">사이드바 색상</li>
					<li className="adjustments-line">
						<div className="badge-colors text-center">
							<span
								className={
									activeColor === 'primary'
										? 'badge filter badge-primary active'
										: 'badge filter badge-primary'
								}
								data-color="primary"
								onClick={() => {
									handleActiveClick('primary');
								}}
							/>
							<span
								className={
									activeColor === 'blue'
										? 'badge filter badge-info active'
										: 'badge filter badge-info'
								}
								data-color="info"
								onClick={() => {
									handleActiveClick('blue');
								}}
							/>
							<span
								className={
									activeColor === 'green'
										? 'badge filter badge-success active'
										: 'badge filter badge-success'
								}
								data-color="success"
								onClick={() => {
									handleActiveClick('green');
								}}
							/>
							<span
								className={
									activeColor === 'orange'
										? 'badge filter badge-warning active'
										: 'badge filter badge-warning'
								}
								data-color="warning"
								onClick={() => {
									handleActiveClick('orange');
								}}
							/>
							<span
								className={
									activeColor === 'red'
										? 'badge filter badge-danger active'
										: 'badge filter badge-danger'
								}
								data-color="danger"
								onClick={() => {
									handleActiveClick('red');
								}}
							/>
						</div>
					</li>
					<li className="header-title">사이드바 확장</li>
					<li className="adjustments-line">
						<div className="togglebutton switch-sidebar-mini d-flex align-items-center justify-content-center">
							<span className="label-switch">OFF</span>
							<CustomInput
								type="switch"
								id="switch-1"
								onChange={handleExpandClick}
								value={sidebarExpand}
								className="mt-n4"
							/>
							<span className="label-switch ml-n3">ON</span>
						</div>
					</li>
					<li className="adjustments-line">
						<div className="togglebutton switch-change-color mt-3 d-flex align-items-center justify-content-center">
							<span className="label-switch">LIGHT MODE</span>
							<CustomInput
								type="switch"
								id="switch-2"
								onChange={handleActiveMode}
								value={darkMode}
								className="mt-n4"
							/>
							<span className="label-switch ml-n3">DARK MODE</span>
						</div>
					</li>
					<li className="button-container">
						zzzzzzz
						<Button
							href="https://carbondatalab.carbonnote.co.kr"
							color="primary"
							block
							className="btn-round"
						>
							Carbon Data Lab
						</Button>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default FixedPlugin;
