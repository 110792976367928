import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import ReactTable from 'components/ReactTable/ReactTable.js';
import DetailModal from 'views/components/DetailModal';
import FormModal from 'views/components/FormModal';
import { EditAndDeleteActions } from 'views/components/Actions';
import requestProjectModel from 'models/requestProject';
import CategoryFilter from 'views/components/CategoryFilter';

const RequestProjectPresenter = ({
	data,
	dataCount,
	loading,
	onClickEdit,
	onClickCreate,
	isOpenForm,
	formMode,
	onClickRow,
	isOpenDetail,
	selectedData,
	targetEditData,
	toggleOffModal,
	handleSelect,
	handleSubmit,
	onClickDelete,
	offset,
	setOffset,
	pageSize,
	setPageSize,
}) => {
	const tableColumns = [
		...requestProjectModel.tableColumns,
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<EditAndDeleteActions
					rowData={row.original}
					onClickEdit={onClickEdit}
					onClickDelete={onClickDelete}
				/>
			),
			width: 120,
		},
	];

	return (
		<>
			<DetailModal
				title="지원사업 신청"
				translation="requestProject"
				data={selectedData}
				isOpen={isOpenDetail}
				toggle={toggleOffModal}
			/>
			<FormModal
				title="지원사업 신청"
				data={targetEditData}
				isOpen={isOpenForm}
				toggle={toggleOffModal}
				mode={formMode}
				model={requestProjectModel.formModel}
				modelName={'requestProject'}
				formColumns={requestProjectModel.formColumns}
				handleSubmit={handleSubmit}
			/>
			<div className="content">
				<Row className="mt-5">
					<Col xs={12} md={12}>
						<Card>
							<CardHeader className="d-flex justify-content-between align-items-center">
								<CardTitle tag="h4">지원사업 신청내역 관리</CardTitle>
							</CardHeader>
							<CategoryFilter
								handleSelect={handleSelect}
								selectModel={requestProjectModel.selectModel}
								selectOptionSets={requestProjectModel.selectOptionSets}
							/>
							<CardBody>
								<ReactTable
									onClickRow={onClickRow}
									data={data}
									dataCount={dataCount}
									loading={loading}
									columns={tableColumns}
									defaultPageSize={10}
									className="-striped -highlight"
									offset={offset}
									setOffset={setOffset}
									pageSize={pageSize}
									setPageSize={setPageSize}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default RequestProjectPresenter;
