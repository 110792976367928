import { useFormik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from 'reactstrap';
import { pick } from 'lodash';
import Select from 'react-select';
import { isDarkModeAtom } from 'atoms/globalAtoms';
import { useAtom } from 'jotai';
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import SimulationFormEditor from './SimulationFormEditor';

const SimulationInfoBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border: 1px solid gray;
	border-radius: 8px;
	margin-bottom: 10px;
	padding: 10px;
	width: 100%;
`;

const SimulationFormModal = ({
	title,
	data,
	isOpen,
	toggle,
	mode,
	model,
	modelName,
	formColumns,
	handleSubmit,
}) => {
	const [isDarkMode] = useAtom(isDarkModeAtom);
	const [formEditorOn, setFormEditorOn] = useState('');

	const { t } = useTranslation(modelName);

	const formik = useFormik({
		initialValues: model,
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		if (mode === 'edit') {
			const editableData = pick(data, [
				'id',
				...formColumns.map(({ key, editable }) => editable && key),
			]);

			formik.setValues({ ...editableData });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const onChangeSelect = (key, value) => {
		formik.setFieldValue(key, value);
	};

	const onChangeCheck = e => {
		formik.setFieldValue(e.target.name, e.target.checked);
	};

	const onClickFormEditor = key => {
		setFormEditorOn(key);
	};

	const onChangeJson = (key, { updated_src }) => {
		formik.setFieldValue(key, JSON.stringify(updated_src));
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			modalClassName={`modal-long ${isDarkMode && 'modal-black'}`}
			size="lg"
		>
			<ModalHeader className="justify-content-center">{`${title} ${
				mode === 'edit' ? '수정하기' : '생성하기'
			}`}</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<Form className="form-horizontal" onSubmit={formik.handleSubmit}>
							{formColumns.map(({ key, editable, type, required, options, label }, index) => (
								<Fragment key={index}>
									{type === 'select' && (
										<Row>
											<Label md="3">{t(key)}</Label>
											<Col md="9">
												<FormGroup>
													<Select
														className="react-select info"
														classNamePrefix="react-select"
														name={key}
														value={options.find(option => option.value === formik.values[key])}
														onChange={item => onChangeSelect(key, item.value)}
														options={options}
														readOnly={!editable}
													/>
												</FormGroup>
											</Col>
										</Row>
									)}
									{type === 'checkbox' && (
										<Col md="9" style={{ height: '50px' }}>
											<FormGroup check>
												<Label check>
													<Input
														id={key}
														name={key}
														type={type}
														onChange={onChangeCheck}
														checked={formik.values[key] ?? true}
														readOnly={!editable}
													/>
													{label}
													<span className="form-check-sign">
														<span className="check"></span>
													</span>
												</Label>
											</FormGroup>
										</Col>
									)}
									{['text', 'number'].includes(type) && (
										<Row>
											<Label md="3">{t(key)}</Label>
											<Col md="9">
												<FormGroup>
													<Input
														id={key}
														name={key}
														type={type}
														onChange={formik.handleChange}
														value={formik.values[key] ?? ''}
														required={required}
														readOnly={!editable}
													/>
												</FormGroup>
											</Col>
										</Row>
									)}
									{type === 'textarea' && (
										<Row>
											<Label md="3">{t(key)}</Label>
											<Col md="9">
												<FormGroup>
													<Input
														id={key}
														name={key}
														type={type}
														onChange={formik.handleChange}
														value={formik.values[key] ?? ''}
														required={required}
														readOnly={!editable}
													/>
												</FormGroup>
											</Col>
										</Row>
									)}
									{mode === 'edit' && type === 'simulation' && (
										<Row>
											<Label md="3">{t(key)}</Label>
											<Col md="9">
												{formEditorOn === key && (
													<SimulationFormEditor
														isOpen={formEditorOn === key}
														toggle={() => setFormEditorOn('')}
														data={formik.values[key] && JSON.parse(formik.values[key])}
														title={
															key.includes('baseline')
																? '베이스라인 폼'
																: key.includes('project')
																? '사업후 배출량 폼'
																: '누출량 폼'
														}
														handleSubmit={value => formik.setFieldValue(key, value)}
													/>
												)}
												<SimulationInfoBox>
													<ReactJson
														src={formik.values[key] && JSON.parse(formik.values[key])}
														name={false}
														collapsed={true}
														displayDataTypes={false}
														onEdit={status => onChangeJson(key, status)}
														onDelete={status => onChangeJson(key, status)}
														onAdd={status => onChangeJson(key, status)}
													/>
													<Button color="info" onClick={() => onClickFormEditor(key)}>
														<i className="tim-icons icon-notes mr-1 pb-1" /> 폼 에디터
													</Button>
												</SimulationInfoBox>
											</Col>
										</Row>
									)}
								</Fragment>
							))}
							<Row className="justify-content-end">
								<Col className="d-flex justify-content-end" md="4" xs="12">
									<Button color="primary" type="submit">
										{mode === 'edit' ? (
											<>
												<i className="tim-icons icon-check-2 mr-1 pb-1" /> 수정하기
											</>
										) : (
											<>
												<i className="tim-icons icon-simple-add mr-1 pb-1" /> 생성하기
											</>
										)}
									</Button>
								</Col>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default SimulationFormModal;
