import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTable from 'components/ReactTable/ReactTable.js';
import DetailModal from 'views/components/DetailModal';
import FormModal from 'views/components/FormModal';
import { EditAndDeleteActions } from 'views/components/Actions';
import methodTypeModel from 'models/methodType';

const MethodTypePresenter = ({
	data,
	dataCount,
	loading,
	onClickEdit,
	onClickCreate,
	isOpenForm,
	formMode,
	onClickRow,
	isOpenDetail,
	selectedData,
	targetEditData,
	toggleOffModal,
	handleSubmit,
	onClickDelete,
	offset,
	setOffset,
	pageSize,
	setPageSize,
}) => {
	const tableColumns = [
		...methodTypeModel.tableColumns,
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<EditAndDeleteActions
					rowData={row.original}
					onClickEdit={onClickEdit}
					onClickDelete={onClickDelete}
				/>
			),
			width: 120,
		},
	];

	return (
		<>
			<DetailModal
				title="방법론 분야"
				translation="methodType"
				data={selectedData}
				isOpen={isOpenDetail}
				toggle={toggleOffModal}
			/>
			{isOpenForm && (
				<FormModal
					title="방법론 분야"
					data={targetEditData}
					isOpen={isOpenForm}
					toggle={toggleOffModal}
					mode={formMode}
					model={methodTypeModel.model}
					modelName={'methodType'}
					formColumns={methodTypeModel.formColumns}
					handleSubmit={handleSubmit}
				/>
			)}
			<div className="content">
				<Row className="mt-5">
					<Col xs={12} md={12}>
						<Card>
							<CardHeader className="d-flex justify-content-between align-items-center">
								<CardTitle tag="h4">방법론 분야 관리</CardTitle>
								<Button onClick={onClickCreate} color="primary">
									<i className="tim-icons icon-simple-add mr-1 pb-1" /> 방법론 분야 등록
								</Button>
							</CardHeader>
							<CardBody>
								<ReactTable
									onClickRow={onClickRow}
									data={data}
									dataCount={dataCount}
									loading={loading}
									columns={tableColumns}
									defaultPageSize={10}
									className="-striped -highlight"
									offset={offset}
									setOffset={setOffset}
									pageSize={pageSize}
									setPageSize={setPageSize}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default MethodTypePresenter;
