import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTable from 'components/ReactTable/ReactTable.js';
import DetailModal from 'views/components/DetailModal';
import methodModel from 'models/method';
import { useTranslation } from 'react-i18next';
import MethodFilter from './MethodFilter';
import MethodFormModal from './MethodFormModal';
import { TagsEditDeleteActions } from 'views/components/Actions';
import TagModal from 'views/components/TagModal';

const MethodPresenter = ({
	data,
	dataCount,
	loading,
	onClickTags,
	onClickEdit,
	onClickCreate,
	isOpenForm,
	formMode,
	onClickRow,
	isOpenDetail,
	selectedData,
	isOpenTags,
	selectedDataTags,
	targetEditData,
	toggleOffModal,
	handleSelect,
	handleSearch,
	handleSubmit,
	methodTypeOptions,
	onClickDelete,
	onClickAddRelation,
	onClickDeleteRelation,
	offset,
	setOffset,
	pageSize,
	setPageSize,
}) => {
	const { t } = useTranslation('method');

	const tableColumns = [
		methodModel.tableColumns[0],
		methodModel.tableColumns[1],
		methodModel.tableColumns[2],
		methodModel.tableColumns[3],
		{
			Header: '지역',
			accessor: 'region',
			Cell: ({ value }) => t(value),
			width: 80,
		},
		{
			Header: '유형',
			accessor: 'type',
			Cell: ({ value }) => t(value),
			width: 80,
		},
		{
			Header: '등록유형',
			accessor: 'registrationType',
			Cell: ({ value }) => t(value),
			width: 120,
		},
		methodModel.tableColumns[4],
		methodModel.tableColumns[5],
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<TagsEditDeleteActions
					rowData={row.original}
					onClickTags={onClickTags}
					onClickEdit={onClickEdit}
					onClickDelete={onClickDelete}
				/>
			),
		},
	];

	return (
		<>
			{selectedDataTags && (
				<TagModal
					isOpen={isOpenTags}
					toggle={toggleOffModal}
					tags={selectedDataTags}
					onClickAdd={onClickAddRelation}
					onClickDelete={onClickDeleteRelation}
				/>
			)}
			<DetailModal
				title="방법론"
				translation="method"
				data={selectedData}
				tags={selectedDataTags}
				isOpen={isOpenDetail}
				toggle={toggleOffModal}
			/>
			<MethodFormModal
				title="방법론"
				methodTypeOptions={methodTypeOptions}
				data={targetEditData}
				isOpen={isOpenForm}
				toggle={toggleOffModal}
				mode={formMode}
				model={methodModel.model}
				modelName={'method'}
				formColumns={methodModel.formColumns}
				handleSubmit={handleSubmit}
			/>
			<div className="content">
				<Row className="mt-5">
					<Col xs={12} md={12}>
						<Card>
							<CardHeader className="d-flex justify-content-between align-items-center">
								<CardTitle tag="h4">방법론 관리</CardTitle>
								<Button onClick={onClickCreate} color="primary">
									<i className="tim-icons icon-simple-add mr-1 pb-1" /> 방법론 등록
								</Button>
							</CardHeader>
							<MethodFilter
								handleSelect={handleSelect}
								handleSearch={handleSearch}
								searchModel={methodModel.searchModel}
								selectModel={methodModel.selectModel}
								searchOptions={methodModel.searchOptions}
								selectOptions={methodTypeOptions}
								searchedCount={dataCount}
							/>
							<CardBody>
								<ReactTable
									onClickRow={onClickRow}
									data={data}
									dataCount={dataCount}
									loading={loading}
									columns={tableColumns}
									defaultPageSize={10}
									className="-striped -highlight"
									offset={offset}
									setOffset={setOffset}
									pageSize={pageSize}
									setPageSize={setPageSize}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default MethodPresenter;
