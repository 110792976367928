const factorModel = {
	model: {
		name: '',
		defaultValue: '',
		example: '',
		suggestedCoefficient: '',
		isVariable: true,
		symbol: '',
		unit: '',
		description: '',
		memo: '',
	},
	formColumns: [
		{ key: 'name', editable: true, type: 'text', required: true },
		{ key: 'defaultValue', editable: true, type: 'number', required: false },
		{ key: 'example', editable: true, type: 'text', required: false },
		{ key: 'suggestedCoefficient', editable: true, type: 'number', required: false },
		{ key: 'isVariable', editable: true, type: 'checkbox', label: '변수인 경우 체크' },
		{ key: 'symbol', editable: true, type: 'text', required: true },
		{ key: 'unit', editable: true, type: 'text', required: true },
		{ key: 'description', editable: true, type: 'textarea', required: true },
		{ key: 'memo', editable: true, type: 'textarea', required: false },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '변수/상수명',
			accessor: 'name',
		},
		{
			Header: '기본값',
			accessor: 'defaultValue',
		},
		{
			Header: '카본노트 추천 계수',
			accessor: 'suggestedCoefficient',
		},
		{
			Header: '변수/상수 여부',
			accessor: 'isVariable',
			Cell: ({ value }) => (value ? '변수' : '상수'),
		},
		{
			Header: '표현식',
			accessor: 'symbol',
		},
		{
			Header: '단위',
			accessor: 'unit',
			width: 100,
		},
		{
			Header: '설명',
			accessor: 'description',
			width: 200,
		},
		{
			Header: '메모',
			accessor: 'memo',
			width: 250,
		},
	],
	searchModel: {
		searchField: 'name',
		searchItem: '',
	},
	searchOptions: [
		{
			label: '변수/상수명',
			value: 'name',
		},
		{
			label: '설명',
			value: 'description',
		},
	],
};

export default factorModel;
