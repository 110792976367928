import { isDarkModeAtom } from 'atoms/globalAtoms';
import { useAtom } from 'jotai';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import styled from 'styled-components';

const HorizontalDivider = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 0 2vw;
	border-top: 1px solid
		${props => (props.isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)')};
`;

const ContextBox = styled.pre`
	font-size: 14px;
	font-family: sans-serif;
`;

const SimulationInfoBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid gray;
	border-radius: 8px;
	margin-bottom: 10px;
`;

const SimulationDetailModal = ({ title, translation, data, factors, isOpen, toggle }) => {
	const [isDarkMode] = useAtom(isDarkModeAtom);

	const { t } = useTranslation(translation);

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			modalClassName={`modal-long ${isDarkMode && 'modal-black'}`}
			size="lg"
		>
			<ModalHeader className="justify-content-center">{`${title} 상세보기`}</ModalHeader>
			<ModalBody>
				{data && (
					<Card>
						<CardBody>
							<dl className="row">
								{data.map(([header, content], index) => (
									<Fragment key={index}>
										<dt style={{ marginTop: '10px' }} className="col-sm-4 text-center text-light">
											{t(header)}
										</dt>
										<dd style={{ marginTop: '10px' }} className="col-sm-8 text-left text-light">
											{header.includes('FormModel') ? (
												<SimulationInfoBox>
													<Table>
														<thead className="text-primary">
															<tr>
																<th className="text-center">계산요소</th>
																<th>표시 여부</th>
																<th>읽기 전용</th>
																<th>필수 값</th>
															</tr>
														</thead>
														<tbody>
															{JSON.parse(content).map((item, index) => (
																<tr key={index}>
																	<td style={{ width: '200px' }} className="text-center">
																		{item.target}
																	</td>
																	<td>{item.visible ? '✅' : '❌'}</td>
																	<td>{item.readonly ? '✅' : '❌'}</td>
																	<td>{item.required ? '✅' : '❌'}</td>
																</tr>
															))}
														</tbody>
													</Table>
												</SimulationInfoBox>
											) : (
												<ContextBox>{content}</ContextBox>
											)}
										</dd>
										{index < data.length - 1 && <HorizontalDivider isDarkMode={isDarkMode} />}
									</Fragment>
								))}
							</dl>
						</CardBody>
					</Card>
				)}
			</ModalBody>
		</Modal>
	);
};

export default SimulationDetailModal;
