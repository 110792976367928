import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ReactTable from 'components/ReactTable/ReactTable.js';
import simulationModel from 'models/simulation';
import SimulationDetailModal from './SimulationDetail';
import SimulationFormModal from './SimulationFormModal';
import { FactorSavingTechEditDeleteActions } from 'views/components/Actions';
import FactorModal from 'views/components/FactorModal';
import SavingTechModal from 'views/components/SavingTechModal';

const SimulationPresenter = ({
	data,
	dataCount,
	loading,
	onClickFactors,
	onClickSavingTechs,
	onClickEdit,
	onClickCreate,
	isOpenForm,
	formMode,
	toggleOffModal,
	onClickRow,
	isOpenDetail,
	selectedData,
	isOpenFactors,
	selectedDataFactors,
	isOpenSavingTechs,
	selectedDataSavingTechs,
	targetEditData,
	handleSearch,
	handleSubmit,
	onClickDelete,
	onClickAddFactorRelation,
	onClickDeleteFactorRelation,
	onClickAddSavingTechRelation,
	onClickDeleteSavingTechRelation,
	offset,
	setOffset,
	pageSize,
	setPageSize,
}) => {
	const { t } = useTranslation('simulation');

	const tableColumns = [
		simulationModel.tableColumns[0],
		simulationModel.tableColumns[1],
		{
			Header: '시뮬레이션 타입',
			accessor: 'type',
			Cell: ({ value }) => t(value),
		},
		simulationModel.tableColumns[2],
		simulationModel.tableColumns[3],
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<FactorSavingTechEditDeleteActions
					rowData={row.original}
					onClickFactors={onClickFactors}
					onClickSavingTechs={onClickSavingTechs}
					onClickEdit={onClickEdit}
					onClickDelete={onClickDelete}
				/>
			),
			width: 200,
		},
	];

	return (
		<>
			{selectedDataFactors && (
				<FactorModal
					isOpen={isOpenFactors}
					toggle={toggleOffModal}
					factors={selectedDataFactors}
					onClickAdd={onClickAddFactorRelation}
					onClickDelete={onClickDeleteFactorRelation}
				/>
			)}
			{selectedDataSavingTechs && (
				<SavingTechModal
					isOpen={isOpenSavingTechs}
					toggle={toggleOffModal}
					savingTechs={selectedDataSavingTechs}
					onClickAdd={onClickAddSavingTechRelation}
					onClickDelete={onClickDeleteSavingTechRelation}
				/>
			)}
			<SimulationDetailModal
				title="시뮬레이션"
				translation="simulation"
				data={selectedData}
				isOpen={isOpenDetail}
				toggle={toggleOffModal}
				factors={selectedDataFactors}
			/>
			<SimulationFormModal
				title="시뮬레이션"
				data={targetEditData}
				isOpen={isOpenForm}
				toggle={toggleOffModal}
				mode={formMode}
				model={simulationModel.model}
				modelName={'simulation'}
				formColumns={simulationModel.formColumns}
				handleSubmit={handleSubmit}
			/>
			<div className="content">
				<Row className="mt-5">
					<Col xs={12} md={12}>
						<Card>
							<CardHeader className="d-flex justify-content-between align-items-center">
								<CardTitle tag="h4">시뮬레이션 관리</CardTitle>
								<Button onClick={onClickCreate} color="primary">
									<i className="tim-icons icon-simple-add mr-1 pb-1" /> 시뮬레이션 등록
								</Button>
							</CardHeader>
							<CardBody>
								<ReactTable
									onClickRow={onClickRow}
									data={data}
									dataCount={dataCount}
									loading={loading}
									columns={tableColumns}
									defaultPageSize={10}
									className="-striped -highlight"
									offset={offset}
									setOffset={setOffset}
									pageSize={pageSize}
									setPageSize={setPageSize}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default SimulationPresenter;
