import { DateTime } from 'luxon';
import { Badge } from 'reactstrap';

const level = ['매우 낮음', '낮음', '중간', '높음', '매우 높음'];

const inquiryModel = {
	model: {
		userId: '',
		level: '',
		name: '',
		duty: '',
		phoneNum: '',
		email: '',
		contents: '',
		reply: '',
		repliedAt: '',
	},
	formModel: {
		state: 'standby',
		reply: '',
	},
	formColumns: [
		{
			key: 'state',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{
					value: 'standby',
					label: '대기',
				},
				{ value: 'complete', label: '완료' },
			],
		},
		{ key: 'reply', editable: true, type: 'textarea', required: true },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '상태',
			accessor: 'state',
			Cell: ({ value }) => (value === 'standby' ? '대기' : '완료'),
			width: 100,
		},
		{
			Header: '문의유형',
			Cell: ({ row }) =>
				row.original.inquiryRelations.map((inquiry, index) => (
					<Badge color="default" key={index}>
						{inquiry.inquiryType.contents}
					</Badge>
				)),
			width: 300,
		},
		{
			Header: '담당자명',
			accessor: 'name',
			width: 100,
		},
		{
			Header: '연락처',
			accessor: 'phoneNum',
		},
		{
			Header: '이메일',
			accessor: 'email',
			width: 200,
		},
		{
			Header: '이해도',
			accessor: 'level',
			Cell: ({ value }) => level[value - 1],
			width: 100,
		},
		{
			Header: '등록일시',
			accessor: 'createdAt',
			Cell: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
			width: 200,
		},
		{
			Header: '답변유무',
			accessor: 'reply',
			Cell: ({ value }) => (value ? '✅' : '❌'),
			width: 100,
		},
	],
	selectModel: {
		state: '',
		inquiryTypeId: '',
	},
	selectOptionSets: [
		[
			{ value: '', label: '전체 상태' },
			{ value: 'standby', label: '대기' },
			{ value: 'complete', label: '완료' },
		],
		[
			{ value: '', label: '전체 문의유형' },
			{ value: '1', label: '감축사업 컨설팅' },
			{ value: '3', label: '탄소 종합 관리' },
			{ value: '4', label: '탄소 배출권 거래' },
			{ value: '5', label: 'Verra 인증 컨설팅' },
			{ value: '6', label: 'CDM 인증 컨설팅' },
			{ value: '7', label: '제휴 문의' },
		],
	],
};

export default inquiryModel;
