/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from 'axios-hooks';
import { useState, useEffect, useRef } from 'react';
import SimulationPresenter from './SimulationPresenter';
import { DateTime } from 'luxon';
import axios from 'axios';
import { omit, isObject, isEmpty } from 'lodash';
import * as math from 'mathjs';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';
import ConfirmDeleteAlert from 'views/components/ConfirmDeleteAlert';

const API_ENDPOINT = '/simulations';
const FACTOR_RELATION_API_ENDPOINT = '/factor-simulations';
const SAVINGTECH_RELATION_API_ENDPOINT = '/saving-tech-simulations';

const DEFAULT_FORM_INPUT = {
	type: 'number',
	visible: true,
	readonly: false,
	required: true,
};

const SimulationContainer = () => {
	const [searchQuery, setSearchQuery] = useState({});
	const [offset, setOffset] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [{ data, loading }, fetchData] = useAxios({
		url: API_ENDPOINT,
		params: { offset, size: pageSize },
	});
	const [selectedData, setSelectedData] = useState(null);
	const [selectedDataId, setSelectedDataId] = useState(null);
	const [selectedDataFactors, setSelectedDataFactors] = useState(null);
	const [selectedDataSavingTechs, setSelectedDataSavingTechs] = useState(null);
	const [targetEditData, setTargetEditData] = useState(null);
	const [isOpenDetail, setIsOpenDetail] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);
	const [isOpenFactors, setIsOpenFactors] = useState(false);
	const [isOpenSavingTechs, setIsOpenSavingTechs] = useState(false);
	const [formMode, setFormMode] = useState('create');
	const [refinedData, setRefinedData] = useState([]);
	const [dataCount, setDataCount] = useState(0);
	const [deleteAlert, setDeleteAlert] = useState(null);
	const [deleteFactorRelationAlert, setDeleteFactorRelationAlert] = useState(null);
	const [deleteSavingTechRelationAlert, setDeleteSavingTechRelationAlert] = useState(null);
	const [addFactorRelationAlert, setAddFactorRelationAlert] = useState(null);
	const [addSavingTechRelationAlert, setAddSavingTechRelationAlert] = useState(null);

	const notificationAlertRef = useRef(null);

	useEffect(() => {
		getQueryData();
	}, [offset, pageSize, searchQuery]);

	useEffect(() => {
		if (data) {
			setRefinedData(data.data.rows ?? []);
			setDataCount(data.data.count);
		}
	}, [data]);

	const getQueryData = async () => {
		try {
			await fetchData({ params: { offset, size: pageSize, ...searchQuery } });
		} catch (e) {
			console.error(e?.response);
			setRefinedData([]);
			setDataCount(0);
		}
	};

	const onClickCreate = () => {
		setFormMode('create');
		setIsOpenForm(true);
	};

	const onClickFactors = async id => {
		const {
			data: { data },
		} = await axios.get(`${API_ENDPOINT}/${id}`);

		const factorList = data.factorSimulation.map(relation => relation.factor);
		setSelectedDataFactors(factorList);
		setSelectedDataId(id);
		setIsOpenFactors(true);
	};

	const onClickSavingTechs = async id => {
		const {
			data: { data },
		} = await axios.get(`${API_ENDPOINT}/${id}`);

		const savingTechList = data.savingTechSimulation.map(relation => relation.savingTech);
		setSelectedDataSavingTechs(savingTechList);
		setSelectedDataId(id);
		setIsOpenSavingTechs(true);
	};

	const onClickEdit = targetData => {
		setFormMode('edit');
		setTargetEditData(targetData);
		setIsOpenForm(true);
	};

	const handleSearch = async params => {
		if (isEmpty(params.searchItem)) {
			if (!isEmpty(searchQuery)) {
				setOffset(0);
				setSearchQuery({});
			}
			return null;
		}

		setOffset(0);
		setSearchQuery(params);
	};

	const getVariables = parsedFormula => {
		const variables = [];
		const traverse = node => {
			if (!(node instanceof math.SymbolNode)) {
				node.args.forEach(childNode => {
					if (childNode instanceof math.SymbolNode) {
						variables.push(childNode.name);
					} else if (childNode instanceof math.ParenthesisNode) {
						traverse(childNode.content);
					} else {
						traverse(childNode);
					}
				});
			} else {
				variables.push(node.name);
			}
		};

		traverse(parsedFormula);
		return variables;
	};

	const handleSubmit = async formData => {
		try {
			if (formData.id) {
				// edit mode
				const dataOnly = omit(formData, ['id']);
				await axios.put(`${API_ENDPOINT}/${formData.id}`, dataOnly);

				const rowIndex = refinedData.findIndex(row => row.id === formData.id);

				refinedData[rowIndex] = { ...formData };
				setRefinedData([...refinedData]);
				setTargetEditData(null);
			} else {
				// create mode

				const baselineFormula = math.parse(formData.baselineFormula);
				const baselineVariables = getVariables(baselineFormula);
				const baselineForm = baselineVariables.map(variable => {
					return {
						target: variable,
						...DEFAULT_FORM_INPUT,
					};
				});
				formData.baselineFormModel = JSON.stringify(baselineForm);

				const projectFormula = math.parse(formData.projectFormula);
				const projectVariables = getVariables(projectFormula);
				const projectForm = projectVariables.map(variable => {
					return {
						target: variable,
						...DEFAULT_FORM_INPUT,
					};
				});
				formData.projectFormModel = JSON.stringify(projectForm);

				let leakageVariables = [];
				if (!isEmpty(formData.leakageFormula)) {
					const leakageFormula = math.parse(formData.leakageFormula);
					leakageVariables = getVariables(leakageFormula);
					const leakageForm = leakageVariables.map(variable => {
						return {
							target: variable,
							...DEFAULT_FORM_INPUT,
						};
					});
					formData.leakageFormModel = JSON.stringify(leakageForm);
				}

				const {
					data: { data: simulationResult },
				} = await axios.post(API_ENDPOINT, formData);

				setRefinedData([simulationResult, ...refinedData]);

				const sumVariables = [...baselineVariables, ...projectVariables, ...leakageVariables];
				const allVariables = [];

				sumVariables.forEach(variable => {
					if (!allVariables.includes(variable)) {
						allVariables.push(variable);
					}
				});

				const factorsCreateResults = await Promise.allSettled(
					allVariables.map(async variable => {
						const {
							data: { data: factorResult },
						} = await axios.post(`/factors`, {
							name: variable,
							defaultValue: '',
							example: '',
							suggestedCoefficient: '',
							isVariable: true,
							symbol: '-',
							unit: '-',
							description: '-',
							memo: '',
						});

						if (factorResult) {
							try {
								await axios.post(`${FACTOR_RELATION_API_ENDPOINT}`, {
									factorId: factorResult.id,
									simulationId: simulationResult.id,
								});
							} catch (e) {
								console.error(e);
								return Promise.reject('relate factor-simulation failed');
							}

							return Promise.resolve(factorResult);
						} else {
							return Promise.reject('create factor failed');
						}
					}),
				);

				if (factorsCreateResults) {
					const options = {
						place: 'tr',
						message: `시뮬레이션 #${simulationResult.id} 생성되었습니다.`,
						type: 'success',
						icon: 'tim-icons icon-check-2',
						autoDismiss: 2,
					};
					notificationAlertRef.current.notificationAlert(options);
				}
			}
		} catch (e) {
			console.log(e);
		} finally {
			setIsOpenForm(false);
		}
	};

	const onClickDelete = id => {
		setDeleteAlert(
			<ConfirmDeleteAlert
				title={'시뮬레이션 삭제'}
				onConfirm={() => handleDelete(id)}
				onCancel={onCancelAlert}
			/>,
		);
	};

	const onCancelAlert = () => {
		setDeleteAlert(null);
		setDeleteFactorRelationAlert(null);
		setDeleteSavingTechRelationAlert(null);
		setAddFactorRelationAlert(null);
		setAddSavingTechRelationAlert(null);
	};

	const handleDelete = async targetId => {
		try {
			await axios.delete(`${API_ENDPOINT}/${targetId}`);
		} catch (e) {
			console.error(e);
		} finally {
			const updatedData = refinedData.filter(row => row.id !== targetId);
			setRefinedData([...updatedData]);

			const options = {
				place: 'tr',
				message: `시뮬레이션 #${targetId} 삭제되었습니다.`,
				type: 'success',
				icon: 'tim-icons icon-check-2',
				autoDismiss: 2,
			};
			notificationAlertRef.current.notificationAlert(options);
		}

		setDeleteAlert(null);
	};

	const toggleOffModal = () => {
		setSelectedData(null);
		setSelectedDataId(null);
		setSelectedDataFactors(null);
		setSelectedDataSavingTechs(null);
		setTargetEditData(null);
		setIsOpenDetail(false);
		setIsOpenForm(false);
		setIsOpenFactors(false);
		setIsOpenSavingTechs(false);
	};

	const onClickRow = async id => {
		const {
			data: { data },
		} = await axios.get(`${API_ENDPOINT}/${id}`);

		const dataList = Object.entries(data).map(([key, value]) => {
			if (value && ['createdAt', 'updatedAt', 'deletedAt'].includes(key)) {
				const localizedDate = DateTime.fromISO(value);
				return [key, localizedDate.toLocaleString(DateTime.DATETIME_SHORT)];
			}

			return [key, value];
		});

		setSelectedData(dataList.filter(([_, value]) => !isObject(value)));
		setIsOpenDetail(true);
	};

	const onClickDeleteFactorRelation = factorId => {
		setDeleteFactorRelationAlert(
			<ReactBSAlert
				warning
				style={{
					display: 'block',
					marginTop: '-100px',
				}}
				title={`계산식 요소 #${factorId} 삭제`}
				onConfirm={() => handleDeleteFactorRelation(factorId)}
				onCancel={onCancelAlert}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				confirmBtnText="삭제"
				cancelBtnText="취소"
				showCancel
				btnSize=""
			>
				{'정말로 삭제하시겠습니까?'}
			</ReactBSAlert>,
		);
	};

	const handleDeleteFactorRelation = async factorId => {
		try {
			await axios.delete(`${FACTOR_RELATION_API_ENDPOINT}`, {
				data: { factorId, simulationId: selectedDataId },
			});
		} catch (e) {
			console.error(e);
		} finally {
			setSelectedDataFactors(prev => prev.filter(factor => factor.id !== factorId));

			const options = {
				place: 'tr',
				message: '시뮬레이션과 계산식 요소 연결이 해제되었습니다. ',
				type: 'success',
				icon: 'tim-icons icon-check-2',
				autoDismiss: 2,
			};
			notificationAlertRef.current.notificationAlert(options);
		}

		setDeleteFactorRelationAlert(null);
	};

	const onClickAddFactorRelation = factorId => {
		setAddFactorRelationAlert(
			<ReactBSAlert
				style={{
					display: 'block',
					marginTop: '-100px',
				}}
				title={`계산식 요소 #${factorId} 추가`}
				onConfirm={() => handleAddFactorRelation(factorId)}
				onCancel={onCancelAlert}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				confirmBtnText="추가"
				cancelBtnText="취소"
				showCancel
			>
				{'추가하시겠습니까?'}
			</ReactBSAlert>,
		);
	};

	const handleAddFactorRelation = async factorId => {
		try {
			await axios.post(`${FACTOR_RELATION_API_ENDPOINT}`, {
				factorId,
				simulationId: selectedDataId,
			});
		} catch (e) {
			console.error(e);
		} finally {
			const { data: newFactor } = await axios.get(`/factors/${factorId}`);
			setSelectedDataFactors(prev => [...prev, newFactor.data]);

			const options = {
				place: 'tr',
				message: '시뮬레이션과 계산식 요소 연결이 추가되었습니다. ',
				type: 'success',
				icon: 'tim-icons icon-check-2',
				autoDismiss: 2,
			};
			notificationAlertRef.current.notificationAlert(options);
		}

		setAddFactorRelationAlert(null);
	};

	const onClickDeleteSavingTechRelation = savingTechId => {
		setDeleteSavingTechRelationAlert(
			<ReactBSAlert
				warning
				style={{
					display: 'block',
					marginTop: '-100px',
				}}
				title={`절감기술 #${savingTechId} 삭제`}
				onConfirm={() => handleDeleteSavingTechRelation(savingTechId)}
				onCancel={onCancelAlert}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				confirmBtnText="삭제"
				cancelBtnText="취소"
				showCancel
				btnSize=""
			>
				{'정말로 삭제하시겠습니까?'}
			</ReactBSAlert>,
		);
	};

	const handleDeleteSavingTechRelation = async savingTechId => {
		try {
			await axios.delete(`${SAVINGTECH_RELATION_API_ENDPOINT}`, {
				data: { savingTechId, simulationId: selectedDataId },
			});
		} catch (e) {
			console.error(e);
		} finally {
			setSelectedDataSavingTechs(prev => prev.filter(savingTech => savingTech.id !== savingTechId));

			const options = {
				place: 'tr',
				message: '시뮬레이션과 절감기술 연결이 해제되었습니다. ',
				type: 'success',
				icon: 'tim-icons icon-check-2',
				autoDismiss: 2,
			};
			notificationAlertRef.current.notificationAlert(options);
		}

		setDeleteSavingTechRelationAlert(null);
	};

	const onClickAddSavingTechRelation = savingTechId => {
		setAddSavingTechRelationAlert(
			<ReactBSAlert
				style={{
					display: 'block',
					marginTop: '-100px',
				}}
				title={`절감기술 #${savingTechId} 추가`}
				onConfirm={() => handleAddSavingTechRelation(savingTechId)}
				onCancel={onCancelAlert}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				confirmBtnText="추가"
				cancelBtnText="취소"
				showCancel
			>
				{'추가하시겠습니까?'}
			</ReactBSAlert>,
		);
	};

	const handleAddSavingTechRelation = async savingTechId => {
		try {
			await axios.post(`${SAVINGTECH_RELATION_API_ENDPOINT}`, {
				savingTechId,
				simulationId: selectedDataId,
			});
		} catch (e) {
			console.error(e);
		} finally {
			const { data: newSavingTech } = await axios.get(`/saving-techs/${savingTechId}`);
			setSelectedDataSavingTechs(prev => [...prev, newSavingTech.data]);

			const options = {
				place: 'tr',
				message: '시뮬레이션과 절감기술 연결이 추가되었습니다. ',
				type: 'success',
				icon: 'tim-icons icon-check-2',
				autoDismiss: 2,
			};
			notificationAlertRef.current.notificationAlert(options);
		}

		setAddSavingTechRelationAlert(null);
	};

	return (
		<>
			{addFactorRelationAlert}
			{deleteFactorRelationAlert}
			{addSavingTechRelationAlert}
			{deleteSavingTechRelationAlert}
			{deleteAlert}
			<NotificationAlert ref={notificationAlertRef} />
			<SimulationPresenter
				data={refinedData}
				dataCount={dataCount}
				loading={loading}
				onClickFactors={onClickFactors}
				onClickSavingTechs={onClickSavingTechs}
				onClickEdit={onClickEdit}
				onClickCreate={onClickCreate}
				isOpenForm={isOpenForm}
				formMode={formMode}
				toggleOffModal={toggleOffModal}
				onClickRow={onClickRow}
				isOpenDetail={isOpenDetail}
				selectedData={selectedData}
				isOpenFactors={isOpenFactors}
				selectedDataFactors={selectedDataFactors}
				isOpenSavingTechs={isOpenSavingTechs}
				selectedDataSavingTechs={selectedDataSavingTechs}
				targetEditData={targetEditData}
				handleSearch={handleSearch}
				handleSubmit={handleSubmit}
				handleDelete={handleDelete}
				onClickDelete={onClickDelete}
				onClickAddFactorRelation={onClickAddFactorRelation}
				onClickDeleteFactorRelation={onClickDeleteFactorRelation}
				onClickAddSavingTechRelation={onClickAddSavingTechRelation}
				onClickDeleteSavingTechRelation={onClickDeleteSavingTechRelation}
				offset={offset}
				setOffset={setOffset}
				pageSize={pageSize}
				setPageSize={setPageSize}
			/>
		</>
	);
};

export default SimulationContainer;
