const standardModel = {
	model: {
		code1: '',
		code2: '',
		code3: '',
		code4: '',
		code5: '',
		codeName1: '',
		codeName2: '',
		codeName3: '',
		codeName4: '',
		codeName5: '',
		link: '',
		main: '',
		description: '',
	},
	formColumns: [
		{ key: 'code1', editable: true, type: 'text', required: true },
		{ key: 'codeName1', editable: true, type: 'text', required: true },
		{ key: 'code2', editable: true, type: 'text', required: true },
		{ key: 'codeName2', editable: true, type: 'text', required: true },
		{ key: 'code3', editable: true, type: 'text', required: true },
		{ key: 'codeName3', editable: true, type: 'text', required: true },
		{ key: 'code4', editable: true, type: 'text', required: true },
		{ key: 'codeName4', editable: true, type: 'text', required: true },
		{ key: 'code5', editable: true, type: 'text', required: true },
		{ key: 'codeName5', editable: true, type: 'text', required: true },
		{ key: 'link', editable: true, type: 'number', required: false },
		{ key: 'main', editable: true, type: 'number', required: false },
		{ key: 'description', editable: true, type: 'text', required: false },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '대분류',
			accessor: 'code1',
			width: 50,
		},
		{
			Header: '중분류',
			accessor: 'code2',
			width: 80,
		},
		{
			Header: '소분류',
			accessor: 'code3',
			width: 80,
		},
		{
			Header: '세분류',
			accessor: 'code4',
			width: 80,
		},
		{
			Header: '세세분류',
			accessor: 'code5',
			width: 100,
		},
		{
			Header: '대분류명',
			accessor: 'codeName1',
		},
		{
			Header: '중분류명',
			accessor: 'codeName2',
		},
		{
			Header: '소분류명',
			accessor: 'codeName3',
		},
		{
			Header: '세분류명',
			accessor: 'codeName4',
		},
		{
			Header: '세세분류명',
			accessor: 'codeName5',
		},
		{
			Header: '태그수',
			accessor: 'countTag',
			width: 80,
		},
		{
			Header: '설명',
			accessor: 'description',
		},
	],
};

export default standardModel;
