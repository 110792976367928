import { Row, Col, Input, Button, Form, Badge } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const SearchButtonInfo = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	div {
		flex: 1;
	}

	div:last-child {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
`;

const CustomOption = ({ innerProps, data, isFocused, isSelected }) => {
	return (
		<div
			style={{ cursor: 'pointer' }}
			className={classNames('d-flex justify-content-between react-select__option', {
				'react-select__option--is-selected': isSelected,
				'react-select__option--is-focused': isFocused,
			})}
			{...innerProps}
		>
			<span>{data.label}</span>
			<span style={{ color: 'gray', fontSize: '0.5rem' }}>{data.subLabel}</span>
		</div>
	);
};

const MethodFilter = ({
	handleSelect,
	handleSearch,
	searchModel,
	searchOptions,
	selectOptions,
	searchedCount,
}) => {
	const defaultSelectOption = { label: '전체 방법론 분야', value: 'default' };
	const [methodTypeOption, setMethodTypeOption] = useState(defaultSelectOption);

	const formik = useFormik({
		initialValues: { ...searchModel },
	});

	const onSelect = item => {
		// formik.setFieldValue('searchItem', '');
		setMethodTypeOption(item);
		handleSelect({ methodTypeId: item.value });
	};

	const onSubmit = e => {
		e.preventDefault();
		// setMethodTypeOption(defaultSelectOption);
		handleSearch(formik.values);
	};

	const defaultSearchOption = searchOptions.find(
		option => option.value === searchModel.searchField,
	);

	return (
		<Form className="px-3 pt-3" onSubmit={onSubmit}>
			<Row className="d-flex justify-content-start align-items-center">
				<Col md="4" xs="12" className="my-1">
					<Select
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => onSelect(item)}
						options={[defaultSelectOption, ...selectOptions]}
						defaultValue={defaultSelectOption}
						value={methodTypeOption}
						components={{
							Option: props => <CustomOption {...props} />,
						}}
					/>
				</Col>
				<Col md="2" xs="3">
					<Select
						name="searchField"
						className="react-select info"
						classNamePrefix="react-select"
						onChange={item => formik.setFieldValue('searchField', item.value)}
						options={searchOptions}
						defaultValue={defaultSearchOption}
						value={searchOptions.find(option => option.value === formik.searchField)}
					/>
				</Col>
				<Col md="4" xs="9">
					<Input
						name="searchItem"
						onChange={formik.handleChange}
						value={formik.searchItem}
						placeholder="검색어를 입력해주세요."
					/>
				</Col>
				<Col md="2" xs="12">
					<Button style={{ width: '100%' }} type="submit" color="warning">
						<SearchButtonInfo>
							<div></div>
							<div>검색</div>
							<div>
								<Badge color="default">{searchedCount}</Badge>
							</div>
						</SearchButtonInfo>
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default MethodFilter;
