import { DateTime } from 'luxon';

const requestProjectModel = {
	model: {
		corporationName: '',
		managerName: '',
		managerDuty: '',
		managerEmail: '',
		isReplace: '',
		isSubsidy: '',
		isLoan: '',
		etc: '',
	},
	formModel: {
		state: '',
		memo: '',
	},
	formColumns: [
		{
			key: 'state',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{ value: 'default', label: '전체 상태' },
				{ value: 'standby', label: '대기' },
				{ value: 'process', label: '진행' },
				{ value: 'complete', label: '완료' },
			],
		},
		{ key: 'memo', editable: true, type: 'textarea', required: false },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '상태',
			accessor: 'state',
			Cell: ({ value }) => (value === 'standby' ? '대기' : value === 'complete' ? '완료' : '진행'),
		},
		{
			Header: '업체명',
			accessor: 'corporationName',
		},
		{
			Header: '담당자명',
			accessor: 'managerName',
		},
		{
			Header: '담당자 직책',
			accessor: 'managerDuty',
		},
		{
			Header: '담당자 이메일',
			accessor: 'managerEmail',
		},
		{
			Header: '설비교체지원사업',
			accessor: 'isReplace',
			Cell: ({ value }) => (value === 1 ? '✅' : '❌'),
			width: 100,
		},
		{
			Header: '보조금',
			accessor: 'isSubsidy',
			Cell: ({ value }) => (value === 1 ? '✅' : '❌'),
			width: 80,
		},
		{
			Header: '융자',
			accessor: 'isLoan',
			Cell: ({ value }) => (value === 1 ? '✅' : '❌'),
			width: 80,
		},
		{
			Header: '기타',
			accessor: 'etc',
		},
		{
			Header: '메모',
			accessor: 'memo',
		},
		{
			Header: '등록일시',
			accessor: 'createdAt',
			Cell: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
			width: 200,
		},
	],
	selectModel: {
		state: '',
	},
	selectOptionSets: [
		[
			{ value: 'default', label: '전체 상태' },
			{ value: 'standby', label: '대기' },
			{ value: 'process', label: '진행' },
			{ value: 'complete', label: '완료' },
		],
	],
};

export default requestProjectModel;
