import { DateTime } from 'luxon';

const supportProjectModel = {
	model: {
		code: '',
		isDirect: '',
		isInvestment: '',
		isDonation: '',
		isSubsidize: '',
		isLoan: '',
		startDate: '',
		endDate: '',
		title: '',
		description: '',
		target: '',
		minAmount: '',
		maxAmount: '',
		requirement: '',
		limit: '',
		link: '',
	},
	formColumns: [
		{ key: 'code', editable: true, type: 'text', required: true },
		{ key: 'isDirect', editable: true, type: 'group-checkbox', required: true },
		{ key: 'isInvestment', editable: true, type: 'group-checkbox', required: true },
		{ key: 'isDonation', editable: true, type: 'group-checkbox', required: true },
		{ key: 'isSubsidize', editable: true, type: 'group-checkbox', required: true },
		{ key: 'isLoan', editable: true, type: 'group-checkbox', required: true },
		{ key: 'startDate', editable: true, type: 'date', required: true },
		{ key: 'endDate', editable: true, type: 'date', required: true },
		{ key: 'title', editable: true, type: 'text', required: true },
		{ key: 'target', editable: true, type: 'text', required: true },
		{ key: 'minAmount', editable: true, type: 'custom-amount', required: true },
		{ key: 'maxAmount', editable: true, type: 'custom-amount', required: true },
		{ key: 'requirement', editable: true, type: 'text', required: true },
		{ key: 'limit', editable: true, type: 'text', required: true },
		{ key: 'link', editable: true, type: 'text', required: true },
		{ key: 'supportProjectFiles', editable: true, type: 'file-list', required: true },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '고유번호',
			accessor: 'code',
		},
		{
			Header: '직접',
			accessor: 'isDirect',
			width: 50,
			Cell: ({ value }) => (value === 1 ? '✅' : '❌'),
		},
		{
			Header: '출자',
			accessor: 'isInvestment',
			width: 50,
			Cell: ({ value }) => (value === 1 ? '✅' : '❌'),
		},
		{
			Header: '출연',
			accessor: 'isDonation',
			width: 50,
			Cell: ({ value }) => (value === 1 ? '✅' : '❌'),
		},
		{
			Header: '보조',
			accessor: 'isSubsidize',
			width: 50,
			Cell: ({ value }) => (value === 1 ? '✅' : '❌'),
		},
		{
			Header: '융자',
			accessor: 'isLoan',
			width: 50,
			Cell: ({ value }) => (value === 1 ? '✅' : '❌'),
		},
		{
			Header: '대상',
			accessor: 'target',
		},
		{
			Header: '제목',
			accessor: 'title',
		},
		{
			Header: '최소 지원금액',
			accessor: 'minAmount',
			Cell: ({ value }) => (value ? value.toLocaleString('ko-KR') + '원' : '-'),
		},
		{
			Header: '최대 지원금액',
			accessor: 'maxAmount',
			Cell: ({ value }) => (value ? value.toLocaleString('ko-KR') + '원' : '-'),
		},
		{
			Header: '시작일',
			accessor: 'startDate',
			Cell: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT),
		},
		{
			Header: '종료일',
			accessor: 'endDate',
			Cell: ({ value }) =>
				value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT) : '',
		},
		{
			Header: '등록일시',
			accessor: 'createdAt',
			Cell: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
			width: 200,
		},
	],
	selectModel: {
		projectType: '',
	},
	searchModel: {
		searchField: 'title',
		searchItem: '',
	},
	searchOptions: [
		{
			label: '제목',
			value: 'title',
		},
		{
			label: '고유번호',
			value: 'code',
		},
	],
	selectOptions: [
		{ value: 'default', label: '전체 유형' },
		{ value: 'isDirect', label: '직접' },
		{ value: 'isInvestment', label: '출자' },
		{ value: 'isDonation', label: '출연' },
		{ value: 'isSubsidize', label: '보조' },
		{ value: 'isLoan', label: '융자' },
	],
};

export default supportProjectModel;
