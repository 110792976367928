import { DateTime } from 'luxon';

const termModel = {
	model: {
		type: '',
		contents: '',
		startDate: '',
	},
	formColumns: [
		{
			key: 'type',
			editable: true,
			type: 'select',
			required: true,
			options: [
				{ value: 'privacy', label: '개인정보처리방침' },
				{
					value: 'service',
					label: '이용약관',
				},
			],
		},
		{ key: 'contents', editable: true, type: 'textarea', required: true },
		{ key: 'startDate', editable: true, type: 'date', required: true },
	],
	tableColumns: [
		{
			Header: '#',
			accessor: 'id',
			width: 50,
		},
		{
			Header: '시작일',
			accessor: 'startDate',
			Cell: ({ value }) =>
				DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss').toLocaleString(DateTime.DATE_SHORT),
		},
		{
			Header: '등록일시',
			accessor: 'createdAt',
			Cell: ({ value }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
			width: 200,
		},
	],
	selectModel: {
		type: '',
	},
	selectOptionSets: [
		[
			{ value: 'default', label: '전체 유형' },
			{ value: 'privacy', label: '개인정보처리방침' },
			{ value: 'service', label: '약관' },
		],
	],
};

export default termModel;
