import { Row, Col, Form } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useEffect } from 'react';

const CategoryFilter = ({ handleSelect, selectModel, selectOptionSets }) => {
	const formik = useFormik({
		initialValues: selectModel,
	});

	const onSelect = (key, value) => {
		formik.setFieldValue(key, value);
	};

	useEffect(() => {
		if (formik.dirty) {
			handleSelect(formik.values);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values]);

	const categories = Object.keys(selectModel);

	return (
		<Form className="px-3 pt-3">
			<Row className="d-flex justify-content-start align-items-center">
				{categories.map((category, index) => (
					<Col md="3" xs="6" key={index}>
						<Select
							name={category}
							className="react-select info"
							classNamePrefix="react-select"
							onChange={item => onSelect(category, item.value)}
							options={selectOptionSets[index]}
							defaultValue={selectOptionSets[index][0]}
							value={selectOptionSets[index].find(option => option.value === formik[category])}
						/>
					</Col>
				))}
			</Row>
		</Form>
	);
};

export default CategoryFilter;
