import React from 'react';
import { Button } from 'reactstrap';

export const EditAndDeleteActions = ({ rowData, onClickEdit, onClickDelete }) => (
	<div
		style={{
			zIndex: 50,
		}}
		className="actions-right"
	>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickEdit(rowData);
			}}
			color="primary"
			size="sm"
			className="btn-round btn-icon mr-3"
		>
			<i className="tim-icons icon-pencil" />
		</Button>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickDelete(rowData.id);
			}}
			color="danger"
			size="sm"
			className={'btn-icon btn-round'}
		>
			<i className="tim-icons icon-trash-simple" />
		</Button>
	</div>
);

export const TagsEditDeleteActions = ({ rowData, onClickTags, onClickEdit, onClickDelete }) => (
	<div
		style={{
			zIndex: 50,
		}}
		className="actions-right"
	>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickTags(rowData.id);
			}}
			color="warning"
			size="sm"
			className="btn-round btn-icon mr-3"
		>
			<i className="tim-icons icon-tag" />
		</Button>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickEdit(rowData);
			}}
			color="primary"
			size="sm"
			className="btn-round btn-icon mr-3"
		>
			<i className="tim-icons icon-pencil" />
		</Button>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickDelete(rowData.id);
			}}
			color="danger"
			size="sm"
			className={'btn-icon btn-round'}
		>
			<i className="tim-icons icon-trash-simple" />
		</Button>
	</div>
);

export const FactorSavingTechEditDeleteActions = ({
	rowData,
	onClickFactors,
	onClickSavingTechs,
	onClickEdit,
	onClickDelete,
}) => (
	<div
		style={{
			zIndex: 50,
		}}
		className="actions-right"
	>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickFactors(rowData.id);
			}}
			color="warning"
			size="sm"
			className="btn-round btn-icon mr-3"
		>
			<i className="tim-icons icon-atom" />
		</Button>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickSavingTechs(rowData.id);
			}}
			color="info"
			size="sm"
			className="btn-round btn-icon mr-3"
		>
			<i className="tim-icons icon-bulb-63" />
		</Button>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickEdit(rowData);
			}}
			color="primary"
			size="sm"
			className="btn-round btn-icon mr-3"
		>
			<i className="tim-icons icon-pencil" />
		</Button>
		<Button
			onClick={e => {
				e.stopPropagation();
				onClickDelete(rowData.id);
			}}
			color="danger"
			size="sm"
			className={'btn-icon btn-round'}
		>
			<i className="tim-icons icon-trash-simple" />
		</Button>
	</div>
);
